.wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 22rem;
}

.inputWrapper {
  margin-bottom: 1rem;
}

.btnWrapper {
  margin-top: 2.25rem;
}

.referralIcon {
  height: 1rem;
  width: 1.25rem;
  color: #83868f;
}
