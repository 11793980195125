.Wrapper {
  width: 100%;
}

.InputWrapper {
  margin-bottom: 1rem;
  position: relative;
}

.FullNameWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.NameInput {
  width: 49%;
}
