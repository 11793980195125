.Separator {
  padding: 0;
  border: 0;
  height: 2.625rem;
  font-weight: 400;
  width: 3px;
  outline: none;
  background: var(--color-input-background, #dbdbdb);
  color: var(--color-text-primary, #181818);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
