.Exercise {
  background-color: transparent;
  margin-bottom: 5px;
}

.SuperSetLineWrapper {
  overflow: inherit;
  width: 2.5rem;
  height: 1.19rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SuperSetLine {
  height: 6rem;
  width: 1px;
  background-color: var(--color-accent-primary, #7782f8);
}

.ExerciseBlock {
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.69rem;
  text-align: center;
  margin-right: 0.75rem;
  border: 1px solid var(--color-accent-primary, #7782f8);
  background-color: var(--color-exercise-group-badge, #ffffff);
  border-radius: var(--border-radius-xsmall, 0.25rem);
}

.ExerciseBlock span {
  color: var(--color-exercise-group-badge-text, #404040);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.Title {
  display: flex;
  text-transform: capitalize;
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.BlockAndTitle {
  display: flex;
}

.MarginBottom {
  margin-bottom: 1.19rem;
}

.Summary {
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  color: var(--color-text-secondary, #457b9d);
  margin-top: 0.25rem;
}
