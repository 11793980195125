/* handle smooth scroll */
html {
  scroll-behavior: smooth;
}

.wrapper {
  padding: 0 1rem;
  background: #1b1e21;
}

.introWrapper {
  margin: 0 -1rem;
  min-height: 100vh;
  position: relative;
}

.introWrapperInner {
  padding: 0 1rem;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/images/hero_bg.jpg');
}

.intro {
  margin: 0 -1rem;
  padding: 0 1rem;
  text-align: center;
}

.logoWrapper {
  padding: 1.125rem;
}

.menu {
  display: none;
}

.logo {
  width: 100%;
  height: auto;
  max-width: 9.25rem;
}

.bannerWrapper {
  position: relative;
}

.gradient {
  bottom: 0;
  width: 100%;
  height: 7rem;
  position: absolute;
  margin: 0 -1rem -0.1rem;
  background: linear-gradient(rgba(26, 29, 32, 0) 0%, #1b1e21 90%, #1b1e21 100%);
}

.bannerText {
  color: #fff;
  padding: 0.75rem 0;
  font-size: 1.875rem;
  line-height: 2.8125rem;
}

.highlightedBannerText {
  color: #e94811;
}

.description {
  color: #cacbd5;
  text-align: center;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  font-family: 'Industry-Book';
}

.coachesWrapper {
  margin: 0 -1rem;
  position: relative;
}

.coaches {
  left: 0;
  width: 100%;
  height: 25rem;
  position: absolute;
  object-fit: contain;
}

.MDdescription {
  display: none;
}

.callToAction {
  display: none;
}

.buttonWrapper {
  z-index: 1;
  position: relative;
  margin-top: 22rem;
}

.bannerRHS {
  display: none;
}

.desktopBtn {
  width: 100%;
  outline: none;
  padding: 1rem 0;
  color: #e94811;
  font-size: 0.75rem;
  border-radius: 0.75rem;
  background: transparent;
  border: 2px solid #e94811;
  font-family: 'Industry-Medium';
}

.bannerLink {
  color: #fff;
  font-size: 0.75rem;
  margin-top: 1.1875rem;
  font-family: 'Industry-Medium';
}

.bannerLink a {
  color: #fff;
  text-decoration: underline;
}

.midSection {
  margin-top: 5rem;
  line-height: 2.75rem;
  font-family: 'Industry-Medium';
}

.midSectionTitle {
  color: #fff;
  text-align: center;
  font-size: 1.875rem;
}

.builderScreenshot {
  width: 100%;
  height: auto;
  margin-top: 2rem;
}

.listWrapper {
  margin-top: 1.5rem;
}

.listWrapper ol {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-position: inside;
}

.listWrapper ol li::before {
  color: #58cb2c;
  content: counter(li);
  margin-right: 0.75rem;
  font-family: 'Industry-Black';
}

.listWrapper ol li {
  color: #cacbd5;
  line-height: 2.25;
  font-size: 1.25rem;
  counter-increment: li;
  font-family: 'Industry-Book';
}

.workoutScreenshot {
  width: auto;
  height: 25rem;
  margin: 0 auto;
}

.screenshotWrapper {
  text-align: center;
  margin: 2rem 0;
}

.workoutMDScreenshot {
  display: none;
}

.toggleWrapper {
  text-align: center;
  margin: 1.4375rem 0 2.5rem;
}

.toggleInner {
  width: 10.5rem;
  display: flex;
  margin: 0 auto;
  line-height: 1;
  flex-wrap: wrap;
  color: #707070;
  font-size: 0.9rem;
  position: relative;
  border-radius: 0.5rem;
  text-transform: uppercase;
  border: 2px solid #777e8a;
  font-family: 'Industry-Book';
}

.toggleBtn {
  width: 50%;
  cursor: pointer;
  padding: 0.5rem 0;
  text-align: center;
}

.activeToggleBtn {
  color: #fff;
  position: relative;
}

.activeToggleBtn span {
  position: relative;
  z-index: 2;
}

.activeToggleBtn:after {
  top: -2px;
  z-index: 1;
  right: -2px;
  content: '';
  position: absolute;
  border-radius: 0.35rem;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(#dd1a18 0%, #f4730c 100%);
}

.priceCardWrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
}

.priceCard {
  width: 50%;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}

.priceCard:nth-child(3) .priceInner {
  border-color: #e94811;
}

.priceInner {
  width: 100%;
  height: 100%;
  min-height: 16.5rem;
  border-radius: 0.5rem;
  background: #25292c;
  padding: 0 0.75rem 1rem;
  border: 1px solid transparent;
}

.planTitle {
  color: #707070;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-family: 'Industry-Bold';
}

.planContentWrapper {
  text-align: center;
  margin-top: 1rem;
}

.price {
  color: #fff;
  font-size: 1.8rem;
  font-family: 'Industry-Bold';
}

.price span {
  color: #707070;
  font-size: 0.9375rem;
  font-family: 'Industry-Medium';
}

.clientCount {
  margin: 1.5rem 0;
  color: #cacbd5;
}

.clientCount svg {
  margin-right: 0.5rem;
}

.priceBtn {
  width: 100%;
  outline: none;
  color: #fff;
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.875rem 0;
  border-radius: 0.5rem;
  text-transform: uppercase;
  border: 1px solid #f4730c;
  font-family: 'Industry-Book';
  background: linear-gradient(#dd1a18 0%, #f4730c 100%);
}

.priceBtn:hover {
  background: linear-gradient(#f25529 0%, #e92929 100%);
}

.priceFooter {
  text-align: center;
  font-size: 0.9375rem;
}

.enterpriseLink {
  color: #707070;
  margin: 0 0 1.8125rem 0;
}

.discountText {
  color: #58cb2c;
  margin-bottom: 6rem;
  line-height: 1.375rem;
}

.enterpriseLink a {
  color: #707070;
  text-decoration: underline;
}

.footer {
  margin: 0 -1rem;
  padding: 1rem 1rem 2rem;
}

.footerSection {
  color: #fff;
  margin-top: 1.5rem;
  font-size: 0.9375rem;
  font-family: 'Industry-Medium';
}

.footerSection ul {
  padding: 0;
  list-style: none;
}

.footerSection ul li {
  line-height: 2;
}

.footerTitle {
  color: #707070;
}

.footerTitle + ul a,
.footerTitle + p a {
  color: #fff;
  position: relative;
}

.footerTitle + ul a:after,
.footerTitle + p a:after {
  left: 0;
  width: 0;
  bottom: 0;
  content: '';
  height: 0.1rem;
  position: absolute;
  margin-bottom: -0.1rem;
  background-color: #fff;
  transition: all 0.1s ease;
}

.footerTitle + ul a:hover:after,
.footerTitle + p a:hover:after {
  width: 100%;
}

@media (min-width: 400px) {
  .coaches {
    height: 30rem;
  }

  .buttonWrapper {
    margin-top: 28rem;
  }
}

@media (min-width: 576px) {
  .footer {
    display: flex;
    align-items: center;
  }

  .footerRHS {
    width: 100%;
    display: flex;
    margin: 0 -0.5rem;
    justify-content: flex-end;
  }

  .footerSection {
    padding: 0 0.5rem;
    max-width: 15rem;
  }
}

@media (min-width: 768px) {
  .header {
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
    position: relative;
    align-items: center;
  }

  .logoWrapper {
    width: 30%;
    text-align: left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .menu {
    width: 70%;
    display: block;
    padding: 1.125rem 0.5rem;
  }

  .menu ul {
    margin: 0;
    padding: 0;
    text-align: right;
  }

  .menu ul li {
    display: inline;
  }

  .menu ul li:not(:first-child) {
    margin-left: 1.5rem;
  }

  .menu ul li:last-child {
    margin-left: 0.75rem;
  }

  .menu ul li a {
    color: #cacbd5;
    font-size: 1rem;
    transition: all 0.1s ease;
    font-family: 'Industry-Bold';
  }

  .menu ul li a.signUpBtn {
    color: #fff;
    width: 6.5rem;
    padding: 0.4rem 0;
    text-align: center;
    line-height: normal;
    display: inline-block;
    border-radius: 0.4375rem;
    border: 1px solid #e92929;
    background: linear-gradient(#f25529 0%, #e92929 100%);
  }

  .menu ul li a.loginBtn {
    width: 6.5rem;
    padding: 0.4rem 0;
    text-align: center;
    line-height: normal;
    display: inline-block;
    border-radius: 0.4375rem;
    transition: all 0.1s ease;
    border: 1px solid #cacbd5;
  }

  .menu ul li a.signUpBtn:hover {
    border: 1px solid transparent;
    background: linear-gradient(#da0c1a 0%, #f8810a 100%);
  }

  .menu ul li a.loginBtn:hover {
    color: #fff;
    background-color: rgba(102, 101, 101, 1);
  }

  .bannerWrapper {
    padding: 3.5rem 0 5rem;
  }

  .bannerLHS {
    text-align: left;
    max-width: 35rem;
  }

  .bannerText {
    font-size: 3rem;
    line-height: 3.25rem;
  }

  .bannerText br {
    display: none;
  }

  .bannerText + .description {
    text-align: left;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-family: 'Industry-Medium';
  }

  .coachesWrapper {
    display: none;
  }

  .callToAction {
    display: block;
    margin-top: 3.5625rem;
  }

  .ctaLink {
    color: #fff;
    padding: 0.8rem 1.5rem;
    transition: all 0.1s ease;
    border-radius: 0.4375rem;
    text-transform: uppercase;
    font-family: 'Industry-Medium';
    background: linear-gradient(#da0c1a 0%, #f8810a 100%);
  }

  .ctaLink:hover {
    background: linear-gradient(#f25529 0%, #e92929 100%);
  }

  .ctaDescription {
    padding: 0.75rem 0;
    color: #cacbd5;
    font-family: 'Industry-Book';
  }

  .buttonWrapper {
    display: none;
  }

  .midSection {
    margin-top: 0;
    padding: 0 1rem;
  }

  .workoutSection .midSectionTitle {
    width: 25rem;
    text-align: left;
  }

  .workoutSection .description {
    display: none;
  }

  .workoutSection .workoutGroup {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .workoutSection .builderScreenshot {
    max-width: 65%;
    margin-top: 1rem;
  }

  .workoutSection .listWrapper {
    padding-left: 1rem;
    width: 35%;
  }

  .workoutSection .listWrapper ol li {
    font-size: 1.2rem;
  }

  .workoutSection .MDdescription {
    display: block;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #cacbd5;
    max-width: 19rem;
  }

  .clientsSection {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .clientsSection .midSectionTitle {
    width: 22rem;
    text-align: left;
  }

  .clientsSection .MDdescription {
    display: block;
    font-size: 0.9rem;
    line-height: 1.5;
    color: #cacbd5;
    max-width: 19rem;
  }

  .clientsSection .workoutScreenshot {
    display: none;
  }

  .screenshotWrapper {
    text-align: right;
    margin-left: 2rem;
    width: calc(100% - 30rem);
  }

  .clientsSection .workoutMDScreenshot {
    width: 100%;
    display: block;
  }

  .clientsSection .description {
    display: none;
  }

  .priceCard {
    width: 25%;
  }

  .footerLHS {
    flex-shrink: 0;
  }

  .footerRHS {
    margin: 0 -1rem;
  }

  .footerSection {
    padding: 0 1rem;
    max-width: 20rem;
  }
}

@media (min-width: 992px) {
  .ceContainer {
    max-width: 992px;
    margin-left: auto;
    margin-right: auto;
  }

  .footerSection {
    padding: 0 1rem;
    max-width: 22rem;
  }

  .bannerWrapper {
    display: flex;
    margin: 0 -0.5rem;
  }

  .bannerLHS {
    width: 50%;
    padding: 0 0.5rem;
  }

  .bannerRHS {
    width: 50%;
    display: block;
    padding: 0 0.5rem;
    position: relative;
  }

  .coaches {
    height: 50rem;
    margin-top: -7.5rem;
  }
}
