.DotsContainer {
  height: 2.5rem;
  width: 2.5rem;
  top: 0.5rem;
  display: flex;
  right: 0.5rem;
  position: absolute;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.75rem;
  transition: all 0.1s ease-in-out;
  border-radius: var(--border-radius-xsmall, 0.25rem);
}

.DotsContainer svg circle {
  fill: var(--color-icon-stroke, #404040);
}

.DotsContainer:hover {
  background-color: var(--color-icon-wrapper-hover, #dbdbdb);
}
