.navContainer {
  display: none;
  font-size: 14px;
}

.navContainer > button {
  max-width: fit-content;
  font-size: 0.8em;
  font-family: 'Industry-medium';
  text-transform: uppercase;
  padding: 1.2em;
  align-items: center;
  line-height: 1.6;
  border-radius: 4px;
  border: 1px solid #e94811;
  height: unset;
}

.navList {
  text-decoration: none;
  list-style-type: none;
  color: #fafafa;
  margin-bottom: 4.3em;
  padding: 0;
  line-height: 1.94em;
  margin-top: 0;
}

.navLink {
  display: flex;
  align-items: center;
  transition-duration: 200ms;
  font-size: 12.8px;
  line-height: 2.6em;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  opacity: 0.2;
}

.navLink:hover {
  opacity: 0.5;
}

.active {
  opacity: 1;
}

@media (min-width: 768px) {
  .navContainer > button {
    font-size: 1em;
    font-weight: 600;
  }
}

@media (min-width: 992px) {
  .navContainer {
    position: sticky;
    top: 50px;
    display: block;
  }

  /* .navContainer > button {
    font-size: 1em;
  } */
}
