.ExerciseTypeInput {
  width: 100%;
  border: none;
  outline: none;
  color: var(--color-text-primary, #181818);
  background-color: var(--color-input-background, #dbdbdb);
  border-radius: var(--border-radius-base, 0.5rem);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 118%;
  letter-spacing: -0.03rem;
  padding: 1rem;
  height: fit-content;
  text-transform: capitalize;
}

.SuggestionsOuterContainer {
  width: 100%;
  position: absolute;
  background-color: var(--color-input-background, #dbdbdb);
  border-radius: var(--border-radius-base, 0.5rem);
  z-index: 2;
  padding: 0.75rem 1rem;
  top: 3.55rem;
}

.SuggestionsInnerContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-y: auto;
  max-height: 10rem;
}

.Suggestion {
  display: flex;
  color: var(--color-text-primary, #181818);
  cursor: pointer;
  list-style: none;
  align-items: center;
  padding: 0.5rem;
  border-radius: var(--border-radius-xsmall, 0.25rem);
  text-transform: capitalize;
  transition: all 0.15s ease-in;
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
}

.Suggestion:hover,
.SuggestionHighlighted {
  background-color: var(--select-color-hover, #959595);
  color: var(--button-text-color-primary, #ffffff);
}

.SuggestionIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
}

.AuthorImg {
  width: 1.625rem;
  height: 1.625rem;
  min-width: 1.625rem;
  min-height: 1.625rem;
  align-items: center;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
}

.AuthorImg img {
  display: block;
  width: 100%;
  height: auto;
}
