.TabsList {
  display: flex;
  position: sticky;
  top: 0px;
  align-items: center;
  z-index: 1;
}

.TabsTrigger {
  background-color: transparent;
  border: none;
  margin-right: 1.5rem;
  padding: 0.5rem 0;
  color: var(--color-text-secondary, #959595);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  text-transform: uppercase;
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  cursor: pointer;
  position: relative;
  transition: color 0.15s linear;
}

.TabsTrigger:hover {
  transition: color 0.15s linear;
  color: var(--color-text-primary, #181818);
}

.TabsContent {
  margin-top: 2rem;
}

.Selected {
  color: var(--color-text-primary, #181818) !important;
  transition: all 0.15s linear;
}

.Selected:after {
  content: '';
  transition: all 0.15s linear;
  display: block;
  position: absolute;
  background: var(--color-text-primary, #181818);
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 3px;
  border-radius: 3px 3px 0 0;
}

.TabsList:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0px;
  height: 0.125rem;
  bottom: 0.125rem;
  border-radius: 0.75rem;
  background: var(--color-divider, #dbdbdb);
}
