.Container {
  color: var(--color-text-primary, #181818);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GoHomeLink {
  margin-left: 0.25rem;
  color: var(--color-accent-primary, #7782f8);
  cursor: pointer;
}
