.Client {
  padding: 1rem 0.5rem;
  height: fit-content;
  width: 8.5625rem;
  max-width: 8.5625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1.5rem;
  border-radius: var(--border-radius-base, 0.5rem);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}

.Client:hover {
  background-color: var(--color-menu-background, #edeff1);
}

.Selected {
  background-color: var(--color-menu-background, #edeff1);
}

.ClientName {
  margin: 0;
  padding: 0;
  color: var(--color-text-primary, #181818);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  text-align: center;
  margin-top: 1rem;
}
