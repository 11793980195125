.Checkbox {
  appearance: none;
  -webkit-appearance: none;
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  border: 1px solid var(--color-text-secondary, #959595);
  border-radius: var(--border-radius-xsmall, 0.25rem);
  background-color: transparent;
  cursor: pointer;
  position: relative;
}

.Checkbox::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px; /* Width of dash */
  height: 2px; /* Height of dash */
  transform: translate(-50%, -50%);
}

.Checkbox:checked {
  border: 1px solid var(--color-accent-primary, #7782f8);
  background-color: var(--color-accent-primary, #7782f8);
}

/* Pseudo-element for the checkmark */
.Checkbox:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6px; /* Width of checkmark */
  height: 14px; /* Height of checkmark */
  border: solid var(--color-text-on-accent, #ffffff);
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -60%) rotate(45deg);
}

/* Pseudo-element for the indeterminate dash */
.Checkbox:indeterminate::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px; /* Width of dash */
  height: 2px; /* Height of dash */
  background-color: var(--color-text-secondary, #959595);
  transform: translate(-50%, -50%);
}
