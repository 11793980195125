.Table ul {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.TableHeadingRow {
  list-style-type: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ColumnHeader {
  padding: 0;
  border: 0;
  outline: none;
  width: 8.5rem;
  box-sizing: border-box;
  color: var(--color-text-primary, #181818);
  text-align: left;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
}

.ColumnHeader:first-child {
  width: 1.875rem;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 1rem;
}

.ColumnHeader:not(:first-child) {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.ColumnHeader:nth-child(3) {
  margin: 0 1rem;
}

.ColumnHeaderEqual {
  width: calc(30%) !important;
  box-sizing: border-box;
  font-size: 13px;
  text-align: left;
  height: 25px;
  line-height: 25px;
  color: #f1f1f1;
  text-align: center;
  margin: 4.8px;
}

.ColumnHeaderEqual:nth-child(3) {
  text-transform: uppercase;
}

.ColumnHeaderTicked {
  width: 27px;
  box-sizing: border-box;
  font-size: 13px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  line-height: 25px;
  margin-left: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.FlexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.AddButtonWrapper {
  margin-right: 1rem;
}

.btnCentralize {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SetActionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--color-accent-primary, #7782f8);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--border-radius-xsmall, 0.25rem);
}

.SetActionButton svg path {
  stroke: var(--color-text-on-accent, #ffffff) !important;
  fill: var(--color-text-on-accent, #ffffff) !important;
}

.addSetBtn {
  border: none;
  cursor: pointer;
  background: none;
}

.addSetBtn:focus {
  outline: none;
}

.addSetIcon {
  width: 1rem;
  height: 1.25rem;
  color: #3c4148;
  fill: currentColor;
  transition: all 0.1s ease;
}

.addSetBtn:hover .addSetIcon {
  color: silver;
}
