.Toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--color-divider, #dbdbdb);
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.TitleInputContainer {
  display: flex;
  align-items: center;
}

.WorkoutTitle {
  color: var(--color-text-primary, #181818);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  margin: 0;
  padding: 0;
  margin-right: 1rem;
}

.Input {
  margin-right: 1rem;
}

.MarginLeft {
  margin-left: 1rem;
}

.GroupIndicatorContainer {
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SuperSetLineWrapper {
  overflow: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SuperSetLine {
  height: 100%;
  width: 1px;
  background-color: var(--color-accent-primary, #7782f8);
}

.SaveProgram {
  display: flex;
  width: 120px;
  height: 40px;
  cursor: pointer;
  background: #5abd78;
  border: 1px solid #5abd78;
  outline: none;
  transition: 0.6s ease-in-out;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: white;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
}

.SaveProgram:hover {
  transition: 0.6s ease-in-out;
  background: rgba(90, 189, 120, 0.746);
  border: 1px solid rgba(90, 189, 120, 0.746);
}

.SaveProgram img {
  padding-right: 2px;
  height: 20px;
  width: 20px;
}

.ProgramCalendarSidebarContainer {
  width: 300px;
  position: fixed;
  z-index: 8;
  top: 80px;
  right: 0;
  width: 300px;
  height: calc(100% - 80px);
  overflow-y: scroll;
  background: rgb(63, 63, 63);
  -webkit-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.DayOutline {
  height: 510px;
  overflow-y: scroll;
  justify-content: center;
}

.SelectedDay {
  font-size: 14px;
  color: white;
  padding: 10px;
  font-weight: 500;
}

.Calendar {
  height: 240px;
  overflow-y: scroll;
}

.SessionTitleContainer {
  display: flex;
  flex-flow: row;
  height: 55px;
  position: relative;
  width: -webkit-calc(90% - 5px);
  margin-bottom: 10px;
}

.SessionTitle {
  display: flex;
  align-items: center;
  color: white;
  margin-left: 20px;
  font-size: 30px;
  height: 100%;
  line-height: 75px;
}

.SessionLogo {
  height: 70px;
  display: flex;
  width: 70px;
  background-color: #545454;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: 2px solid white;
}

.SessionLogo h1 {
  font-weight: 400;
  color: white;
}

.SessionNotes {
  max-width: 50%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5.625rem;
}

.SessionNotesReview {
  max-width: 61rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.25rem;
  margin-bottom: 2.5rem;
}

.SessionNotesReviewColumn {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.SessionNotesReviewColumn p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.ExerciseContainer {
  height: auto;
  position: relative;
  clear: both;
}

.Exercise {
  display: flex;
}

.AddExerciseButtonContainer {
  width: fit-content;
  margin-bottom: 5.38rem;
}
