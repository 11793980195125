.Container {
  border-bottom: 1px solid var(--color-divider, #dbdbdb);
  /* border-top: 1px solid var(--color-divider, #dbdbdb); */
  margin-bottom: 1.5rem;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Buttons {
  display: flex;
  gap: 1rem;
}

.Heading {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  color: var(--color-text-primary, #181818);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.44375rem */
  letter-spacing: -0.0275rem;
  padding: 0;
  margin: 0;
}
