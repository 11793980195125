.sign {
  font-size: 25px;
  color: #e94811;
}

.accordion {
  width: 100%;
}

.accordionWrapper {
  margin-top: 50px;
  border-bottom: 1px solid rgb(133, 133, 133);
  color: #ffffff;
}
.accordionHeader {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
  border: 1px solid rgb(133, 133, 133);
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0px;
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0.005em;
}

.accordionHeader > h3 {
  margin: 0;
}
.accordionBody {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}

.accordionBody[aria-expanded='true'] {
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}
.answer {
  padding: 15px;
}

@media (min-width: 768px) {
  .section {
    padding-right: 8.77vw;
  }
}
