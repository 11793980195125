.DialogOverlay {
  background-color: var(--modal-overlay, rgba(0, 0, 0, 0.5));
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 100;
}

.DialogContent {
  display: inline-block;
  z-index: 100;
  background: var(--modal-background, #ffffff);
  border-radius: var(--border-radius-base, 0.5rem);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: 85vw;
  max-height: 85vh;
  padding: 2.5rem;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent:focus {
  outline: none;
}

.DialogHeader {
  margin-bottom: 1rem;
}

.DialogTitle {
  margin: 0;
  text-align: center;
  color: var(--color-text-primary, #181818);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.8375rem */
  letter-spacing: -0.0875rem;
}

.DialogDescription {
  text-align: center;
  max-width: 18rem;
  display: flex;
  margin-top: 1rem;
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  flex-wrap: wrap;
}

.IconButton {
  font-family: inherit;
  border: 0;
  background-color: transparent;
  border-radius: 100%;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.875rem;
  right: 0.5rem;
  cursor: pointer;
}

.CloseIcon {
  width: 35px;
}

.CloseIcon path {
  stroke: var(--color-icon-stroke, #404040);
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.Trigger {
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: transparent;
  border: 0;
}

.Buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 3rem;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
