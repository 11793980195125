.modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: block;
  position: fixed;
  overflow-y: auto;
  background-color: rgba(60, 65, 72, 0.8);
  z-index: 50;
}

.modal::-webkit-scrollbar {
  display: none;
}

.modalInner {
  width: auto;
  display: flex;
  margin: 1.5rem;
  max-width: 28rem;
  position: relative;
  align-items: center;
  min-height: calc(100% - (1rem * 2));
}

.modalInnerDynamic {
  width: auto;
  display: flex;
  position: relative;
  align-items: center;
  min-height: calc(100% - (1rem * 2));
}

.modalInner > div, .modalInnerDynamic > div {
  width: 100%;
}

@media (min-width: 640px) {
  .modalInner {
    margin: 2rem auto;
    min-height: calc(100% - (2rem * 2));
  }
}
