.Content {
  border-radius: var(--border-radius-base, 0.5rem);
  padding: 1.5rem;
  pointer-events: auto !important;
  background-color: var(--color-card-background, #ffffff);
  box-shadow: var(--color-box-shadow, 0px 4px 20px 0px rgba(0, 0, 0, 0.1));
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 101;
  overflow-y: scroll;
}
.Content:focus {
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px,
    0 0 0 2px var(--violet-7);
}
.Content[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.Content[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.Content[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.Content[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}

.Arrow {
  fill: var(--color-card-background, #ffffff);
}

.Close {
  font-family: inherit;
  border: 0;
  background-color: transparent;
  border-radius: 100%;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.875rem;
  right: 0.5rem;
  cursor: pointer;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
