.Wrapper {
  background-color: var(--color-card-background, #ffffff);
  border-radius: var(--border-radius-large, 1rem);
  padding: 1rem;
  margin: 0;
}

.Wrapper:nth-child(2) {
  margin-right: 1rem;
  margin-left: 1rem;
}

.LabelWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.Value {
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 2.85rem */
  letter-spacing: -0.03rem;
  color: var(--color-accent-primary, #7782f8);
}

.Label {
  margin: 0;
  padding: 0;
  margin-top: 0.25rem;
  color: var(--color-text-primary, #181818);
  min-width: fit-content;
  white-space: nowrap;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  text-transform: capitalize;
}

@media (max-width: 900px) {
  .Wrapper {
    max-width: fit-content;
  }
}
