.Container {
  display: flex;
  flex-direction: column;
  width: 14.3125rem;
  padding: 1.5rem 1rem;
  background-color: var(--color-card-background, #ffffff);
  height: 100%;
  border-radius: var(--border-radius-base, 0.5rem);
  position: relative;
}

.SectionHeading {
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-primary, #181818);
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
}

.ChannelList {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.MarginBottom {
  margin-bottom: 2.5rem;
}

.ClientList {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.ListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-top: 0.5rem;
}

.ListItem:first-child {
  margin-top: 0;
}

.ChannelButton {
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  color: var(--color-text-primary, #181818);
  border-radius: var(--border-radius-base, 0.5rem);
  transition: all 0.1s linear;
  box-sizing: content-box;
  padding: 0.75rem 1rem;
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  text-align: left;
  text-transform: capitalize;
}

.ChannelButton:hover,
.ClientChannelButton:hover,
.Selected {
  cursor: pointer;
  background-color: var(--button-background-primary, #7782f8);
  color: var(--button-text-color-primary, #ffffff);
}

.SettingsContainer {
  display: flex;
  margin-top: auto;
  width: 100%;
  align-items: center;
}

.SettingsButton {
  width: fit-content;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  color: #707070;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.SettingsButton > div {
  display: flex;
  align-items: center;
}

.SettingsIcon {
  width: 1.5rem;
  color: rgb(161, 161, 161);
  fill: currentColor;
  transition: all 0.3s ease;
  margin-right: 10px;
}

.Divider {
  display: block;
  height: 2px;
  border: 0;
  border-top: 2px solid #26292b;
  margin: 1em 0;
  padding: 0;
}

.SearchClients {
  position: relative;
  margin-bottom: 1rem;
}

.ChatSettingsText {
  color: rgb(206, 206, 206);
}

.ChatSettingsText:hover {
  color: rgb(255, 255, 255);
}

@media (max-width: 500px) {
  .Container {
    padding: 5px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: unset;
  }

  .List {
    flex-direction: row;
  }

  .ChannelButton {
    border-radius: 20px;
    margin-top: unset;
  }
}
