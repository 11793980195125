.TextAreaContainer {
  all: unset;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: border-color 0.15s ease-in-out;
}

.Error {
  font-size: 0.8rem;
  color: var(--color-danger, #ff5050);
  display: block;
  position: absolute;
  bottom: -16px;
  left: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
}

.TextAreaContainer textarea {
  flex: 1;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  background-color: var(--color-input-background);
  border-radius: var(--border-radius-base, 0.5rem);
  border: none;
  border-left: unset;
  border-bottom: unset;
  outline: none;
  padding: 0.75rem 1rem;
  color: var(--color-text-primary, #181818);
  max-height: 260px;
  overflow-y: auto;
  resize: none;
  position: relative;
  border: 1px solid transparent;
  transition: border-color 0.15s ease-in-out;
  z-index: 1;
}

.TextAreaContainer.WrapLabel:focus-within {
  border: 1px solid var(--color-input-border-active, #959595);
}

.TextAreaContainer:not(.WrapLabel):focus-within > textarea {
  outline: none;
  border: 1px solid var(--color-input-border-active, #959595);
}

.Label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.InternalLabel {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  flex-grow: 1;
}

.WrapLabel {
  background-color: var(--color-input-background);
  border-radius: var(--border-radius-base, 0.5rem);
  cursor: text;
}

.LabelContainer {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0;
  margin-bottom: -0.6rem;
  z-index: 3;
  cursor: text;
}

.IconWrapper {
  margin-bottom: 0.22rem;
  margin-right: 0.4rem;
}

.IconWrapper svg {
  stroke: var(--color-icon-stroke, #404040);
  color: var(--color-icon-stroke, #404040);
}

.SVGFill svg,
.SVGFill path {
  fill: var(--color-icon-stroke, #404040);
}
