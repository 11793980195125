.NavItem {
  width: 78px;
  height: 68px;
  margin-bottom: 1.5rem;
}

.NavItem a {
  display: flex;
  flex-direction: column;
  color: var(--color-text-navitem, #404040);
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: var(--border-radius-base, 0.5rem);
  transition: all 0.1s ease-in-out;
  width: 100%;
  height: 100%;
}

.NavItem a:hover:not(.Active, :active, .active) {
  background-color: var(--color-navitem-background-hover, #dbdbdb) !important;
}

.NavItem a:active,
.NavItem a.active,
.Active {
  background-color: var(--color-accent-primary, #7782f8) !important;
  color: var(--color-text-navitem-active, #ffffff) !important;
}

.NavIcon {
  display: block;
  margin-bottom: 0.5rem;
  max-width: fit-content;
}

.NavIcon path {
  transition: all 0.1s ease-in-out;
  fill: var(--color-text-navitem, #404040) !important;
}

.Active .NavIcon path {
  fill: var(--color-text-navitem-active, #ffffff) !important;
}

.LinkLabel {
  display: inline-block;
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

.ClientProfileNavItem {
  width: 11.5625rem;
  margin-bottom: 0.5rem;
  height: 3rem;
}

.ClientProfileNavItem a {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0.75rem 1rem;
  gap: 1rem;
}

.ClientProfileIcon {
  margin-bottom: 0;
  /* transform: scale(0.8);  */
  width: 1.5rem;
  height: 1.5rem;
}

.ClientProfileLabel {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.03375rem;
}

.SvgStroke path {
  transition: all 0.1s ease-in-out;
  stroke: var(--color-text-navitem, #404040) !important;
  fill: none !important;
}

.SvgFill path {
  transition: all 0.1s ease-in-out;
  fill: var(--color-text-navitem, #404040) !important;
  stroke: none !important;
}

.Active .SvgStroke path {
  transition: all 0.1s ease-in-out;
  stroke: var(--color-text-navitem-active, #ffffff) !important;
  fill: none !important;
}

.Active .SvgFill path {
  transition: all 0.1s ease-in-out;
  fill: var(--color-text-navitem-active, #ffffff) !important;
  stroke: none !important;
}
