.Container {
  background-size: contain;
  background-color: var(--color-accent-primary, #7782f8);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  width: 5rem;
  height: 5rem;
  min-width: 5rem;
  min-height: 5rem;
  border-radius: var(--border-radius-base, 0.5rem);
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.Small {
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: var(--border-radius-xsmall, 0.25rem);
}

.XSmall {
  width: 1.625rem;
  height: 1.625rem;
  min-width: 1.625rem;
  min-height: 1.625rem;
  border-radius: 0.1625rem;
}

.Container span {
  color: var(--color-text-on-accent, #ffffff);
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 105%; /* 1.8375rem */
  letter-spacing: -0.0875rem;
}

.Container.Small span,
.Container.XSmall span {
  color: var(--color-text-on-accent, #ffffff);
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}
