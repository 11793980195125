.ContainerStyle {
  background-color: var(--color-card-background, #ffffff);
  border-radius: var(--border-radius-base, 0.5rem);
  padding: 2rem 1.875rem;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 58rem;
}

.AddSuperSet {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.Row {
  display: flex;
  flex-direction: row;
}

.Column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ExerciseBlock {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.BlockWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ExerciseWrapper {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  flex: 1;
  height: 100%;
  margin-right: 1.25rem;
  max-width: 19.625rem;
}

.ExerciseWrapperReview {
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  flex: 1;
  height: 100%;
  padding-right: 20px;
}

.Exercise {
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
}

.VideoURL {
  width: 6.75rem;
  height: 3.75rem;
  margin: 0 0 0.75rem;
  display: inline-block;
}

.VideoThumb {
  width: 6.75rem;
  height: 3.75rem;
  object-fit: cover;
  border-radius: 0.2rem;
}

.ExerciseNotes {
  display: flex;
}

.ExerciseNotesHeading {
  font-size: 18px;
  color: #5e6065;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}

.ExerciseNotesCoachParagraph {
  width: 100%;
  color: #cacbd5;
  padding: 0;
  margin: 0;
}

.ExerciseNotesClientParagraph {
  width: 100%;
  color: #58cb2c;
  padding: 0;
  margin: 0;
}

.SetWrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.SetWrapperReview {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.SetTable {
  box-sizing: border-box;
  height: auto;
  outline: none;
}

.ExerciseMoveContainer {
  width: 50px;
  max-width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MoveButton,
.AnalyticsButton,
.MoveButtonDisabled {
  position: relative;
  display: flex;
  width: 1.5rem;
  align-items: center;
  justify-content: center;
  overflow: visible;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 0;
  border-radius: var(--border-radius-xsmall, 0.25rem);
}

/* Move buttons */
.MoveButton path {
  stroke: var(--color-icon-stroke, #404040);
  transition: fill 0.1s ease-in-out;
}

.MoveButton:hover {
  background-color: var(--color-icon-wrapper-hover, #dbdbdb);
}

.MoveButton:hover path {
  stroke: var(--color-icon-stroke, #404040);
}

/* Analtyics - exercise history button */
.AnalyticsButton {
  height: 1.5rem;
}

.AnalyticsButton svg {
  transition: all 0.1s ease-in-out;
  fill: var(--color-icon-stroke, #404040);
}

.AnalyticsButton:hover svg {
  transition: all 0.1s ease-in;
  fill: var(--color-icon-wrapper-hover, #dbdbdb);
}

.AnalyticsButton:hover path {
  transition: all 0.1s ease-in;
  stroke: var(--color-icon-stroke, #404040);
}

/* Disabled */
.MoveButtonDisabled {
  cursor: not-allowed;
}

.MoveButtonDisabled path {
  stroke: var(----color-icon-stroke-disabled, #dbdbdb);
}

/* Button tool tip */
.MoveButton p,
.AnalyticsButton p,
.MoveButtonDisabled p {
  width: max-content;
  height: 0;
  line-height: 0;
  position: absolute;
  left: 35px;
  bottom: 0;
  color: transparent;
  transition: color 0.1s ease-in;
  font-size: 12px;
}

.MoveButton:hover p,
.AnalyticsButton:hover p {
  color: var(--color-icon-stroke, #404040);
}

.FooterText {
  cursor: pointer;
  font-size: 0.85rem;
  padding: 0.65rem 0.5rem;
}

.FooterLink {
  display: flex;
  color: silver;
  align-items: center;
  justify-content: center;
  transition: color 0.1s ease;
}

.FooterLink:hover {
  color: #fff;
}

.FooterTextIcon {
  width: 1rem;
  height: 1rem;
  display: flex;
  color: silver;
  margin-right: 1rem;
  align-items: center;
  padding-top: 0.1rem;
  justify-content: center;
  border-radius: 0.2125rem;
  transition: all 0.1s ease;
  border: 2px solid silver;
}

.FooterLink:hover .footerTextIcon {
  color: #fff;
  border: 2px solid #fff;
}

.ExerciseHistoryWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 420px;
  margin-left: 8px;
  margin-bottom: 1rem;
  overflow-x: hidden;
  border-radius: var(--border-radius-base, 0.5rem);
}

.FullWidth {
  width: 100%;
}

.NotesLabel {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-primary, #181818);
  padding: 0;
  margin: 0.5rem 0;
}
