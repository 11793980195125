.Header {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  justify-content: space-between;
}

.ProfileWrapper {
  display: flex;
  align-items: center;
}

.ProgramBuilderHeader {
  width: -webkit-calc(100% - 82.156px);
  height: 40px;
  background-color: rgb(20, 20, 20);
  color: white;
  font-size: 30px;
  z-index: 2;
  display: flex;
  float: right;
  position: fixed;
  top: 80px;
  right: 0;
  box-shadow: 6px 6px 6px #00000029;
}

.PageTitle {
  display: flex;
  position: relative;
  align-items: center;
}

.PageTitle h2 {
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 90%; /* 2.25rem */
  letter-spacing: -0.025rem;
  color: var(--color-text-primary, '#181818');
}

.PageTitle button,
.BackToClients button {
  border: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  height: 1.5rem;
}

.PageTitle button:focus {
  outline: none;
}

.Account {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-left: 0.75rem;
  cursor: pointer;
}

.ChevronDropDown {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.Notification {
  float: left;
  margin-left: 25px;
  height: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Notification img {
  /* margin-left: auto; */
  border-left: 1px solid rgba(183, 183, 184, 0.4);
  border-right: 1px solid rgba(183, 183, 184, 0.4);
  padding-right: 15px;
  padding-left: 15px;
}

.NotificationLogout {
  float: left;
  margin-left: 25px;
  height: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.NotificationLogout img {
  /* margin-left: auto; */
  border-right: 1px solid rgba(183, 183, 184, 0.4);
  padding-right: 20px;
}

.MyWorkouts {
  height: 80px;
  margin-left: auto;
  margin-top: 3px;
  display: flex;
  align-items: center;
}

.MyWorkouts span {
  color: #cacbd5;
  font-size: 14px;
  cursor: pointer;

  transition: all 0.2s linear;

  font-size: 1rem;
  border-bottom: 3px solid transparent;
}

.Logout:hover {
  background-color: #333333b6;
  justify-content: center;
}

.Logout.Active {
  background-color: #333333b6;
  justify-content: center;
  border-bottom: 3px solid #d60509;
}

.MyWorkout {
  color: white;
  font-size: 18px;
  width: 130px;
  text-align: center;
}

.MyWorkouts:hover {
  color: rgb(228, 229, 240);
  transition: all 0.2s linear;
}

.LineRule {
  border-bottom: 1px solid rgba(245, 245, 245, 0.685);
  margin: 13px 28px;
  width: auto;
}

.LineRuleAnalytics {
  border-bottom: 1px solid rgba(245, 245, 245, 0.685);
  margin: 13px 28px;
  width: auto;
  display: none;
}

.Loading {
  color: white;
  font-size: 12px;
  position: absolute;
  margin-left: 20px;
  margin-top: 20px;
}

.LoadingWithArrow {
  color: white;
  font-size: 12px;
  position: absolute;
  margin-left: 60px;
  margin-top: 20px;
}

.ChevronDropDown path {
  stroke: var(--color-icon-stroke, #404040);
}

.BackBtn {
  margin-right: 1rem;
}

.BackBtn svg {
  stroke: var(--color-icon-stroke #404040);
}

.FlexWrapper {
  display: flex;
  align-items: center;
}

.SubNotification {
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  position: absolute;
  background-color: var(--color-accent-primary, '#7782F8');
  color: var(--color-text-on-accent, '#ffffff');
  z-index: 1;
}

.NotificationBell {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: var(--border-radius-xsmall, 0.25rem);
  width: 2rem;
  height: 2rem;
  transition: all 0.1s ease-in-out;
}

.NotificationBell:hover {
  background-color: var(--color-icon-wrapper-hover, '#DBDBDB');
}

.NotificationBell svg {
  fill: var(--color-icon-stroke, '#404040');
  width: 1.5rem;
  height: 1.5rem;
}

.UnreadCount {
  position: absolute;
  top: -5px;
  left: 14px;
  background: var(--color-danger, #ff5050);
  color: var(--button-text-color-danger, #ffffff);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 0.75rem */
  text-transform: uppercase;
}

.ClientProfileLeftSection {
  display: flex;
  gap: 2rem;
}

.Logo {
  height: 2.5rem;
}

.BackToClients {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.BackToClients span {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .WorkoutLink {
    display: none;
  }
}
