.FormWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(85vh - 13rem);
}

.Form {
  display: flex;
  flex-direction: column;
  width: 47.4375rem;
  position: relative;
  background-color: white;
  border: 0;
  outline: 0;
  padding-right: 0.5rem;
}

.FormWrapper::-webkit-scrollbar {
  width: 0.25rem;
}

.FormWrapper::-webkit-scrollbar-track {
  background: var(--color-menu-background, #edeff1);
  border-radius: 50px;
  border: 0;
}

.FormWrapper::-webkit-scrollbar-thumb {
  background-color: var(--color-accent-primary, #7782f8);
  border-radius: 50px;
  border: 0;
}

.FlexContainer {
  display: flex;
  align-items: center;
}

.MacroInputsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: fit-content;
}

.CaloriesMacrosContainer {
  padding-bottom: 1rem;
  padding-right: 0.69rem;
}

.TopLevelInputs {
  width: 4.875rem;
}

.InputLabel {
  max-width: none !important;
}

.OneRemGap {
  gap: 1rem;
}

.MacroBreakdownInput {
  display: flex;
  width: 4.7rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.GridHeading {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.6rem;
  width: 100%;
}
.GridHeading span {
  width: 4.7rem;
  text-align: center;
}

.GridHeading div {
  flex-grow: 1;
}

.Grid {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-end;
}

.SpaceBetween {
  gap: 0.5rem;
  width: 100%;
}

.MacrosContainer {
  justify-content: space-between;
}

.SmallMediumText {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary, #181818);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
}

.TotalPercentage {
  transition: all 0.15s ease-in-out;
  margin-left: 5.35rem;
  margin-top: 1rem;
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-demi-bold, 'PlusJakartaSans-DemiBold', sans-serif);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  min-height: 15.39px;
}

.MacroLabel {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  white-space: nowrap; /* Ensure the label stays on one line */
  flex-grow: 1;
  margin-right: 0.5rem;
  max-width: 3.5rem;
}

.PieContainer {
  gap: 2.25rem;
}

.DaysToPractice {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 0.75rem;
}

.DaysToPractice span {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
}

.SubHeading {
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.MacrosHeading {
  color: var(--color-text-primary, #181818);
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.MacrosTypeWidth {
  width: 10.9375rem;
  margin-bottom: 2.25rem;
}

.MacrosCheckboxes {
  display: flex;
  gap: 0.75rem;
}

.MicrosContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: wrap;
  height: 150px;
  width: 60%;
}

.GridContainer {
  position: relative;
  overflow: visible;
  z-index: 1;
}

.ErrorMessage {
  display: flex;
  position: absolute;
  flex-wrap: nowrap;
  bottom: -38px;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  color: var(--color-danger, #ff5050);
  padding: 0;
  margin: 0;
}

.CenterInputText {
  text-align: center;
}

.ErrorPercentage {
  color: var(--color-danger, #ff5050);
  transition: all 0.15s ease-in-out;
}

.DayTitleWrapper {
  display: flex;
  align-items: center;
  position: fixed;
  top: 2.25rem;
}

.DayTitleInput {
  padding: 0;
  border: 0;
  min-height: 29.39px;
  margin: 0;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  color: var(--color-text-primary, #181818);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.8375rem */
  letter-spacing: -0.0875rem;
  height: 2.25rem;
  outline: 0;
  width: auto;
}

.Title {
  margin-top: 4px;
}

.TitleActionButtons {
  display: flex;
  gap: 0.25rem;
}

.KeyPieChart {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.15625rem;
}

.Disabled {
  opacity: 0.5;
}

.UneditableTitleWrapper {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  cursor: pointer;
}

.UneditableTitleWrapper path {
  fill: var(--color-icon-stroke, #404040);
  stroke: var(--color-icon-stroke, #404040);
}
