.heroContainer {
  width: 100%;
  max-width: 1920px;
  margin: auto;
  padding: 150px 5px;
  position: relative;
  font-family: 'Industry-bold';
  color: #ffffff;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  overflow: hidden;
}

.heroContainer img {
  object-fit: cover;
  position: absolute;
  height: 100%;
  min-width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.heroContent {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heroTitle {
  font-family: 'Industry-bold';
  font-size: 2.9em;
  line-height: 1.32;
  font-weight: 700;
  text-transform: uppercase;
  color: #e94811;
  margin: 0 0 0.44em 0;
}

.heroSubtitle {
  font-family: 'Industry-bold';
  color: #ffffff;

  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.32;
  letter-spacing: 0.03em;
  margin: 0 0 0.35em 0;
  font-size: 1.7em;
}

.heroSubtitle span {
  color: #e94811;
  text-transform: none;
}

.heroContainer p {
  color: #ffffff;
  font-size: 1em;

  max-width: 327px;

  margin-bottom: 2em;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.005em;
}

.heroContainer button {
  max-width: fit-content;
  font-family: 'Industry-medium';
  text-transform: uppercase;
  align-items: center;
  border-radius: 4px;
  line-height: 0.9;
  padding: 1.5em 2.5em;
  font-weight: 600;
  font-size: 1em;
  border: 1px solid #e94811;
  height: unset;
}

@media (min-width: 576px) {
  .heroContainer > p {
    max-width: 400px;
  }
}

@media (min-width: 768px) {

  .heroContainer {
    padding: 4.77em 8.33vw;
  }

  .heroTitle {
    font-size: 4.07em;
  }

  .heroSubtitle {
    font-size: 2.4em;
    width: 100%;
  }

  .heroContainer p {
    max-width: 40.52em;
    font-size: 1.04em;
  }

  .heroContainer button {
    font-size: 1.05em;
    line-height: 0.9;
    padding: 1.5em 2.5em;
    font-weight: 600;
    font-size: 1.1em;
  }
}

@media (min-width: 992px) {
  .heroContainer {
    padding-top: 10.5em;
  }

  .heroTitle {
    font-size: 4.77em;
  }

  .heroSubtitle {
    max-width: 100%;
  }
}