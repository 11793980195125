.Container {
  position: relative;
  max-width: 53.1875rem;
  width: calc(65vw);
  height: calc(79vh - 5rem);
  display: flex;
  flex-direction: column;
}

.TopSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.88rem;
  margin-top: 1rem;
}

.SearchClients {
  width: fit-content;
}

.ClientList {
  display: grid;
  grid-gap: 2rem 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(8.5625rem, 1fr));
  grid-auto-rows: 8.75rem;
  overflow-y: scroll;
  flex: 1;
}
