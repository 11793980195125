.DayContainer {
  display: flex;
  align-items: center;
}

.DayButton {
  margin: 0.25rem;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  border-radius: var(--border-radius-base, 0.5rem);
  background-color: var(--color-icon-wrapper-hover, #dbdbdb);
  color: var(--color-text-primary, #181818);
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.DayButton.Active {
  background-color: var(--color-accent-primary, #7782f8); /* Purple color for active days */
  color: var(--color-text-on-accent, #ffffff);
}

.DayButton:focus {
  outline: none;
}

.EveryDayButton {
  display: flex;
}
