.formWrapper {
  text-align: center;
  width: 17.6875rem;
}

.planInput {
  outline: none;
  font-size: 1rem;
  height: 1.875rem;
  width: 18.75rem;
}

.error {
  opacity: 0.9;
  font-weight: 500;
  padding-bottom: 0;
  margin: 0.25rem 0 0;
  color: rgb(209, 47, 47);
}

.progressWrapper {
  height: 0.25rem;
  position: relative;
  max-width: 18.75rem;
  margin: 2rem auto 0;
  border-radius: 0.25rem;
  background-color: #171a1c;
}

.progressBar {
  top: 0;
  left: 0;
  height: 100%;
  max-width: 100%;
  position: absolute;
  border-radius: 0.25rem;
  transition: all 0.1s ease;
  background-color: #d73a27;
}

.dndWrapper {
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  width: 18.75rem;
  padding: 0.25rem;
  min-height: 7.5rem;
  align-items: center;
  margin: 0.25rem auto 0;
  justify-content: center;
  transition: all 0.1s ease;
  border: 1px dashed #cacbd5;
}

.dndWrapper:hover {
  opacity: 0.75;
}

.activeWrapper {
  border-color: #d73a27;
}

.fileName {
  width: 100%;
  color: #d73a27;
  margin-bottom: 0;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}
