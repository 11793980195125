.Wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Header {
  display: flex;
}

.DateRange {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: var(--border-radius-large, 1rem);
  background: var(--color-card-background, #ffffff);
}

.DateRange span {
  color: var(--color-text-primary, #181818);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.44375rem */
  letter-spacing: -0.0275rem;
  text-transform: uppercase;
}

.LeftStatsContainer {
  display: flex;
  flex-direction: column;
}

.TopLevelStatsWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.WebAnalyticsContainer {
  display: flex;
  flex-direction: column;
}

.WebAnalyticsTitle h2 {
  padding: 0;
  color: var(--color-text-primary, #181818);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.44375rem */
  letter-spacing: -0.0275rem;
  margin-bottom: 1rem;
  margin: 2rem 0 1rem 0;
}

.WebAnalyticsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.PerformanceListWrapper {
  width: 100%;
}

.RightStatsContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
}

@media (max-width: 900px) {
  .TopLevelStatsWrapper {
    flex-direction: column;
  }
}
