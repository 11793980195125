.wrapper {
  height: fit-content;
  position: relative;
}

.Day {
  width: 15.875rem;
  min-height: 10.25rem;
  padding: 1rem;
  margin-bottom: 30px;
  position: sticky;
  margin-right: 12px;
  z-index: 1;
  transition: all 0.1s linear;
  background-color: var(--color-card-background, #ffffff);
  border-radius: var(--border-radius-large, 1rem);
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.19rem;
}

.DayHeading {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 105%; /* 1.575rem */
  letter-spacing: -0.045rem;
  text-transform: uppercase;
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  margin: 0;
  padding: 0;
  margin-bottom: 1.19rem;
}

.WorkoutName {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.DayContent {
  position: relative;
}

.DayContentQuickEdit {
  position: relative;
  padding-bottom: 65px;
}

.Button {
  color: white;
  background-color: transparent;
  border: 0;
  outline: none;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 600;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  transition: color 0.1s ease;
}

.Button:hover {
  cursor: pointer;
  color: #d73a27;
}

.Title {
  display: flex;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
}

.Workouts {
  justify-content: left;
  margin: 0;
}

.WorkoutsQuickEdit,
.WorkoutsQuickEditFeedback {
  display: flex;
  margin: 0;
  width: fit-content;
  overflow-y: scroll;
}

.WorkoutsQuickEditFeedback {
  max-width: 410px;
}

.Divider,
.DividerNotes {
  top: 0;
  position: sticky;
  margin: 0 14px 0 18px;
}

.Divider div,
.DividerNotes div {
  border-left: 2px solid #3c4147;
  height: 100%;
}

.DividerNotes div {
  border-left: 2px solid transparent;
}

.RestDay {
  text-align: center;
}

.RestDayHeading {
  font-weight: 500;
  font-size: 1.375rem;
  text-align: center;
  color: var(--color-text-primary, #181818);
  margin: 0;
  margin-bottom: 0.5rem;
}

.WorkoutFinished {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 1.19rem 0;
  gap: 0.25rem;
}

.WorkoutCompleteText {
  color: var(--color-text-primary, #181818);
  text-align: center;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  text-transform: uppercase;
}

.FinishedDayButton {
  width: 85%;
  height: 40px;
  display: flex;
  color: #707070;
  font-size: 12px;
  cursor: pointer;
  margin: 2rem 0 10px 0;
  border-radius: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;
  border: 2px solid #707070;
  background-color: transparent;
}

.FinishedDayButton:hover {
  background-color: rgba(102, 101, 101, 0.746);
  transition: all 0.1s linear;
  color: white;
}

.DayButton,
.CloseButton {
  width: 85%;
  height: 40px;
  display: flex;
  color: #707070;
  font-size: 12px;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;
  border: 2px solid #707070;
  background-color: transparent;
}

.CloseButton {
  width: 50%;
  margin: 0;
}

.DayButton:hover,
.CloseButton:hover {
  background-color: rgba(102, 101, 101, 0.746);
  transition: all 0.1s linear;
  color: white;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.EditIcon {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.DayButton:hover .EditIcon {
  filter: grayscale(1) brightness(2);
}

.DropDownContainer {
  position: absolute;
  left: 12.15rem;
  top: 1rem;
  font-size: 12px;
  z-index: 2;
  margin: 0;
}

.DropDownContainerQuickEdit {
  position: absolute;
  left: 20.05rem;
  top: 2rem;
  font-size: 12px;
  z-index: 2;
  z-index: 11002;
  margin: 0;
}

.DropDownBox {
  background-color: #3c4148;
  border-radius: 7px;
  height: 55px;
  width: 250px;
  position: absolute;
  top: -55px;
  left: -54px;
  color: #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.DropDownButtons {
  margin: auto;
  transition: color 0.1s ease;
}

.DropDownButtons:hover {
  cursor: pointer;
  color: white;
}

.ArrowDown {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #3c4148;
}

.AddButtonWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.AddButtonWrapper:last-child {
  margin-top: 0.5rem;
}

.AddText {
  margin-left: 0.75rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  text-transform: uppercase;
  color: var(--color-text-primary, #181818);
}

.EditButtonsContainer button:nth-child(2) {
  margin-top: 0.5rem;
}

.AthleteFeedbackWrapper p,
.WorkoutNotesFeedback {
  font-size: 13px;
  font-weight: 400;
  color: rgba(219, 219, 219, 0.938);
  margin-top: 5px;
  margin-bottom: 5px;
}

.WorkoutNotesFeedback {
  margin-bottom: 40px;
}

.ExerciseFeedbackText {
  font-size: 13px;
}
