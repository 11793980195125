.Sidebar {
  height: 100vh;
  background-color: var(--color-menu-background, #edeff1);
  border-right: 1px solid var(--color-border, #dbdbdb);
  position: fixed;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 86px;
}

.ClientProfileSidebar {
  width: 13.8125rem;
  padding: 1.5rem 1rem;
  align-items: flex-start;
}

.ClientProfileNameSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-self: center;
}

.ImageDiv {
  background-size: contain;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;
  z-index: 1;
}

.ClientProfileName {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%;
  letter-spacing: -0.045rem;
  text-align: center;
}
