.SelectContainer {
  width: 100%;
  position: relative;
}

.ErrorMessage {
  font-size: 0.8rem;
  color: #d95353;
  display: block;
  position: absolute;
  bottom: -16px;
  left: 0;
}

.SelectTrigger {
  padding: 0.75rem 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  border-radius: var(--border-radius-base, 0.5rem);
  gap: 5px;
  background-color: var(--color-input-background, #dbdbdb);
  color: var(--color-text-primary, #181818);
  min-width: 100%;
  width: 100%;
  cursor: pointer;
  border: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  transition: all 0.1s ease-in-out;
}

.SelectTrigger:hover {
  background-color: var(--select-color-hover, #959595);
  transition: all 0.1s ease-in-out;
}

.SelectTrigger[data-placeholder] {
  color: var(--color-text-primary, #181818);
}

.SelectTrigger:focus {
  outline: none;
}

.SelectIcon {
  color: hsl(250, 43%, 48%);
}

.SelectContent {
  margin-top: 3.125rem;
  overflow: hidden;
  background-color: var(--color-input-background, #dbdbdb);
  border-radius: var(--border-radius-base, 0.5rem);
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 200;
  width: 100%;
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  color: var(--color-text-primary, #181818);
  border-radius: var(--border-radius-small, 0.3125rem);
  display: flex;
  align-items: center;
  padding: 0.5rem;
  position: relative;
  user-select: none;
  cursor: pointer;
  z-index: 201;
}

.SelectItem[data-state='checked'] {
  background-color: var(--select-color-hover, #959595);
  color: var(--button-text-color-primary, #ffffff);
}

.SelectItem[data-disabled] {
  color: hsl(255, 3.7%, 78.8%);
  pointer-events: none;
}

.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--select-color-hover, #959595);
  color: var(--button-text-color-primary, #ffffff);
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: black;
}

.SelectSeparator {
  height: 1px;
  background-color: hsl(252, 77.8%, 89.4%);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: hsl(250, 43%, 48%);
  cursor: default;
}

.Label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
