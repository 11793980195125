.Container {
  position: relative;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  height: 85vh;
}

.TopSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

.FilesWrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.AddProgramButtonWrapper:hover .AddProgramButton {
  background-image: linear-gradient(#e04c49, #e9a065);
  cursor: pointer;
}

.AddProgramButtonWrapper:hover .AddProgramText {
  color: #cacbd5;
  cursor: pointer;
}

.AddProgramText {
  transform: rotate(90deg);
  font-size: 16px;
  color: #707070;
  position: absolute;
  right: 10%;
  top: -220%;
  width: 140px;
  height: 100px;
  font-family: 'Industry-Bold', sans-serif;
}

.AddProgramButton {
  border-radius: 8px;
  font-size: 50px;
  height: 58px;
  width: 58px;
  outline: none;
  background-image: linear-gradient(#dd1a18, #f4730c);
  border-width: 0;
}

.SearchDocuments {
  display: flex;
  align-items: center;
  width: 14.8125rem;
}

.SearchDocuments form {
  width: fit-content;
  height: fit-content;
}

.ButtonWrapper {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -1.25rem;
  z-index: 2;
}

.NoDocuments {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.BulkActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
