.Container {
  width: 100%;
}

.NotesTableContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 3.5rem);
}

.GroupWrapperFlex {
  display: flex;
  gap: 1.5rem;
  width: 100%;
  justify-content: space-between;
}

.GroupWrapper {
  margin-bottom: 2rem;
}

.Exercise,
.ExercisePrev {
  display: flex;
  width: calc(50% - 0.5rem);
}

.ExercisePrev {
  width: calc(50% + 2.5rem);
}

.WithoutPreviousExercise {
  width: 100%;
}

.ExerciseInner {
  display: flex;
  flex-direction: column;
}

.ExerciseHistoryWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 420px;
}

.ExerciseGroup {
  color: #fff;
  width: 2.5rem;
  display: flex;
  height: 2.5rem;
  font-weight: 500;
  font-size: 0.9rem;
  align-items: center;
  margin-right: 0.75rem;
  border-radius: 0.375rem;
  justify-content: center;
  background-color: #3d4244;
  border: 2px solid #e94811;
}

.NotesLabel {
  padding: 0;
  margin: 0 0 0.5rem;
  text-transform: capitalize;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.Type {
  padding: 0;
  margin: 0 0 0.5rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.GroupIndicatorContainer {
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SuperSetLineWrapper {
  overflow: inherit;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SuperSetLine {
  height: 100%;
  width: 1px;
  background-color: var(--color-accent-primary, #7782f8);
}

.FullWidth {
  width: 100%;
}

.WorkoutNotesWrapper {
  margin-bottom: 1rem;
}

.NotesContainer {
  display: flex;
  width: 100%;
  gap: 1.5rem;
}

.SpaceBetween {
  display: flex;
  justify-content: space-between;
}

.notesInnerWrapper {
  padding: 0;
  width: 100%;
  border: none;
  resize: none;
  outline: none;
  color: #f1f1f1;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: transparent;
}

.TableWrapper {
  padding: 1rem 0 2rem;
}

.AthleteFeedbackContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AthleteFeedbackWrapper {
  width: 100%;
}

.WithoutPreviousExerciseTable {
  width: calc(50% - 4rem);
}
