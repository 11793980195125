.Container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: var(--color-card-background, #ffffff);
  border-radius: var(--border-radius-base, 0.5rem);
  padding: 1.5rem;
}

.QuickEditView {
  padding: 0;
}

.Divider {
  display: block;
  height: 2px;
  border: 0;
  border-top: 1px solid var(--color-divider, #dbdbdb);
  margin: 0.5rem 0;
  padding: 0;
}

table td {
  color: var(--color-text-primary, #181818);
  font-size: 14px;
}

.CloseBoxButton {
  position: absolute;
  right: 8px;
  top: 8px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
}

.InnerContainer label {
  color: var(--color-text-primary, #181818);
}

.PRContainer {
  padding: 2px;
}

.SectionHeading {
  color: var(--color-text-primary, #181818);
  margin-bottom: 4px;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.44375rem */
  letter-spacing: -0.0275rem;
  text-transform: uppercase;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
}

.PRList {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--color-divider, #dbdbdb);
  padding: 0.25rem 0;

  color: var(--color-text-primary, #181818);
  font-size: 12px;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
}

.HistoryContainer {
  padding: 2px;
}

.HistoryList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: var(--color-divider, #dbdbdb);
  border-top-width: 1px;
  border-top-style: solid;
  color: var(--color-text-primary, #181818);
  text-align: center;
  font-size: 14px;
  padding: 6px 0;
}

.Graph {
  width: 100%;
}

.GraphSelectorContainer {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.GraphSelector {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  cursor: pointer;
  border-radius: var(--border-radius-base, 0.5rem);
  border: 0;
  color: var(--color-text-on-accent, #ffffff);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  text-transform: uppercase;
  max-height: 3rem;
  gap: 0.5rem;
}

.Loading {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-text-primary, #181818);
}

.Loading span {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.Icon {
  width: 20px;
}

.NoData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.NoData p {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  padding: 0;
}

.DateEntry {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  padding: 0;
  margin: 0;
  color: var(--color-text-primary, #181818);
}
