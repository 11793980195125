.HeadContent {
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ProgramNameInput {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 90%; /* 2.25rem */
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  pointer-events: none;
  text-transform: capitalize;
  width: fit-content;
}

.ProgramNameInput:focus {
  outline: none !important;
  pointer-events: all;
  color: var(--color-text-secondary, #959595);
}

.TitleWrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.9vh;
  margin-left: 2.12rem;
}

.CalendarContent {
  overflow: hidden;
}

.ClientNameContainer p {
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
}

.ProgramSelectWrapper {
  display: flex;
  margin: 2.5rem 2.5rem 0 0;
  justify-content: flex-start;
}

.MinWidth {
  min-width: 10.5rem;
}

.AddWeekButtonWrapper {
  width: fit-content;
  margin-bottom: 3.5rem;
  margin-top: 3rem;
}

.EmptyContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.MinHeight {
  min-height: 29.39px;
  margin-bottom: 1rem;
}
