.PieChart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.875rem;
  height: 8.875rem;
}

.Disabled {
  background-color: var(--color-menu-background, #edeff1);
  width: 8.875rem;
  height: 8.875rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
