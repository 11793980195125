.Input {
    border: 1px solid #696969;
    box-sizing: border-box;
    border-radius: 9px;
    background-color: transparent;
}

.wrapper {
    width: 100%;
}

.inputWrapper {
    margin-bottom: 1rem;
    position: relative;
}

.FullNameWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.nameInput {
    width: 49%;
}

.OpenCloseEye {
    position: absolute;
    right: 5px;
    top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    color: #ffffff;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.OpenCloseEye svg {
    height: 24px;
    width: 24px;
}
