.Container {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.Color {
  width: 2.625rem;
  min-width: 2.625rem;
  height: 2.625rem;
  min-height: 2.625rem;
  border: 1px solid var(--color-divider, #dbdbdb);
  cursor: pointer;
  border-radius: var(--border-radius-base, 0.5rem);
  margin-right: 0.5rem;
}

.Input {
  padding-left: 8px;
  cursor: pointer;
  height: 30px;
  width: 100%;
  text-align: left;
}

.ColorPicker {
  position: absolute;
  left: 25%;
  z-index: 999;
  border-radius: 8px !important;
  font-family: 'Rubik', sans-serif !important;
}

.ColorPicker > div {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
