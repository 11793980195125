.SwitchRoot {
  all: unset;
  width: 3.75rem;
  height: 2rem;
  background-color: var(--color-menu-background, #edeff1);
  border-radius: 9999px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.SwitchThumb {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--color-text-secondary, #959595);
  border-radius: 9999px;
  transition: transform 110ms;
  transform: translateX(4px);
  will-change: transform;
}

.SwitchThumb[data-state='checked'] {
  transform: translateX(2rem);
  background-color: var(--color-accent-primary, #7782f8);
}
