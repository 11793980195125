.CommunityContainer {
  display: flex;
  min-height: calc(100vh - 8.25rem);
  max-height: calc(100vh - 8.25rem);
}

.Sidebar {
  display: flex;
  flex-direction: column;
  width: 14.3125rem;
}

.ChatContainer {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  overflow-y: hidden;
  justify-content: space-between;
  flex: 1;
}

.MessageUl {
  margin: 0;
  overflow-y: scroll;
  text-align: left;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 10px;
}

.MessageLi {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
}

.MessageLi:nth-child(1) {
  margin-top: 0;
}

.MessageLi:nth-last-child(2) {
  margin-bottom: 1.875rem;
}

.NoMessageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

@media (max-width: 500px) {
  .CommunityContainer {
    flex-direction: column;
  }
  .Sidebar {
    flex-direction: row;
  }

  .ChatContainer {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    overflow-y: hidden;
    justify-content: space-between;
    flex: 1;
    border-left: 1px solid #171a1ca4;
    border-bottom: 1px solid #171a1ca4;
    border-right: 1px solid #171a1ca4;
    border-top-right-radius: unset;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top: unset;
  }
}
