.CompareContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.RightProgressContainer,
.LeftProgressContainer {
  width: 280px;
}

.LeftProgressContainer {
  margin-right: 20px;
}

.ImageContainerModal,
.ImageContainerModal > img {
  display: flex;
  width: 100%;
  border-radius: 20px;
  height: 350px;
  object-fit: cover;
}

.NoImageContainerModal {
  border: 1px #707070 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 100%;
  border-radius: 20px;
  background-color: var(--color-no-image-background, #404040);
}

.NoImageContainerModal svg path {
  fill: var(--color-text-primary, #181818);
}

.InputsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 1rem;
}
