.HeadContent {
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ProgramNameInput {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 90%; /* 2.25rem */
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  pointer-events: none;
  text-transform: capitalize;
  width: fit-content;
}

.ProgramNameInput:focus {
  outline: none !important;
  pointer-events: all;
  color: var(--color-text-secondary, #959595);
}

.TitleWrapper {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.9vh;
  margin-left: 2.12rem;
}

.CalendarContent {
  overflow: hidden;
}

.AddWeekButtonWrapper {
  width: fit-content;
  margin-bottom: 3.5rem;
  margin-top: 3rem;
}
