.Clients {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.ClientsWrapper {
  width: fit-content;
  padding: 0 0.75rem 1.5rem;
}

.ProgramTitleInput {
  display: flex;
  justify-content: center;
  font-size: 15px;
  outline: none;
}

.FullWidth {
  width: 100%;
}

.EmptyIcon {
  width: 6rem;
  height: 6rem;
}

.EmptyListTitle {
  margin: 1rem 0;
}

.EmptyListContentWrapper p {
  margin: 0 0 0.5rem;
}

.SearchClients {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  width: 14.8125rem;
}

.SearchClients form {
  width: fit-content;
  height: fit-content;
}

.EmptyClients {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}

.HowToAddText {
  margin-top: 0;
}

.Centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Centered p {
  padding: 0;
  margin: 0;
}

.Flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
}

.BulkActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.PausedAccessAssignButton {
  color: var(--color-danger, #ff5050);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.PausedAccessAssignButton:hover {
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.ClientActive {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  color: var(--color-text-primary, #181818);
}

.BulkActionsButton {
  display: flex;
  flex-direction: row;
}
