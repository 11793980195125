.container {
  width: 100%;
  padding: 1rem;
  max-width: 32rem;
  margin: 0 0 1rem 1rem;
  border-radius: 15px;
  background-color: #171a1c;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.294), 0 5px 10px rgba(0, 0, 0, 0.24);
}

.planTitle {
  margin: 0;
  color: #fff;
  font-size: 1.25rem;
  padding: 0 0 0.5rem;
  border-bottom-style: solid;
  border-bottom-width: 0.05rem;
  border-bottom-color: #707070;
}

.highlight {
  color: #d73a27;
  text-transform: uppercase;
}

.innerContainer {
  padding: 1rem 0;
}

.contentSection {
  color: #fff;
}

.contentSection:not(:last-child) {
  margin-bottom: 1.5rem;
}

.clientCountWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  justify-content: space-between;
}

.clientCount {
  margin: 0;
  font-size: 1rem;
}

.progressWrapper {
  height: 0.25rem;
  position: relative;
  border-radius: 0.25rem;
  background-color: white;
}

.progressBar {
  top: 0;
  left: 0;
  height: 100%;
  max-width: 100%;
  position: absolute;
  border-radius: 0.25rem;
  background-image: linear-gradient(#dd1a18, #f4730c);
}

.title {
  opacity: 0.5;
  font-size: 0.85rem;
  margin: 0 0 0.25rem 0;
  text-transform: uppercase;
}

.value {
  margin: 0;
  font-size: 1rem;
}

.footer {
  display: flex;
  padding: 1rem 0 0;
  align-items: center;
  border-top-style: solid;
  border-top-width: 0.05rem;
  border-top-color: #707070;
  justify-content: space-between;
}

.button {
  border: 0;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  transition: all 0.1s ease;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.1);
}

.changeBtn {
  color: white;
  background-image: linear-gradient(#dd1a18, #f4730c);
  border-radius: 5px;
}

.cancelBtn {
  color: #d73a27;
  min-width: 6.9375rem;
  background-color: transparent;
}

.cancelBtn:hover {
  color: white;
  background-color: #d73a27;
}

/* modal start */
.modalContainer {
  margin: auto;
  padding-top: 1rem;
  position: relative;
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.787);
  background: linear-gradient(rgb(34, 34, 34), rgb(26, 27, 27));
}

.modalClose {
  right: 1rem;
  position: absolute;
}

.modalClose img {
  height: 0.75rem;
  cursor: pointer;
}

.modalSaveIcon {
  text-align: center;
}

.modalSaveIcon img {
  height: 3.75rem;
  padding: 0.75rem;
  border-radius: 50%;
  box-shadow: 0px 5px 20px black;
}

.modalLargeText {
  color: white;
  font-weight: 600;
  text-align: center;
  font-size: 1.25rem;
}

.modalSmallText {
  color: white;
  font-weight: 400;
  text-align: center;
  font-size: 0.75rem;
}

.modalButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1rem 0.25rem;
  background-color: #252525;
}

.modalDelete {
  border: 0;
  outline: none;
  color: white;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.875rem;
  background-color: #d73a27;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.1);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.modalDelete:hover {
  background-color: rgb(211, 78, 63);
}
/* modal end */

/* change plan modal start */
.changePlanWrapper {
  color: #ffffff;
  text-align: center;
  padding: 1rem 1.5rem;
}

.planDetails {
  margin: 0;
  font-size: 3rem;
}

.sup {
  top: -0.75rem;
  position: relative;
}

.sup,
.sub {
  font-size: 0.75rem;
}

.price {
  margin: 0 0.25rem;
}

.separator {
  height: 2;
  border: 0;
  width: 100%;
  max-width: 15rem;
  margin: 0 auto 0.5rem;
  border-bottom-style: solid;
  border-bottom-width: 0.05rem;
  border-bottom-color: #171a1c;
}

.planSelectField {
  outline: none;
  margin-top: 2rem;
}

.selectClientsWrapper {
  margin-top: 1.5rem;
}

.removalWrapper {
  display: flex;
  flex-wrap: wrap;
  max-height: 200px;
  padding: 0.5rem 0;
  overflow-y: scroll;
  justify-content: center;
}

.textInputWrapper {
  margin-top: 0.5rem;
}

.discountCode {
  width: 100%;
  padding: 0.25rem;
  max-width: 15rem;
  text-align: center;
}

.discountCode:focus {
  outline: none;
}

.loadingSvg {
  margin: auto;
  width: 1.5rem;
  height: 0.7rem;
  fill: currentcolor;
}

.secondaryButton {
  color: #ffffff;
  background-color: transparent;
}

/* cancel plan modal start  */
.modalTitle {
  font-size: 1.5rem;
}
