.Button {
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  border: 0;
  width: 100%;
  display: inline-flex;
  height: 3rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  outline: none;
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  text-transform: uppercase;
  border-radius: var(--button-border-radius, 0.5rem);
  transition: background-color 0.15s ease, color 0.15s ease;
  position: relative;
}

.Icon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Icon svg {
  width: 1.125rem;
  height: 1.125rem;
}

.Icon svg path {
  stroke: var(--button-text-color-primary, #ffffff);
}

/* PRIMARY BUTTON */
.Primary {
  color: var(--button-text-color-primary, #ffffff);
  background: var(--button-background-primary, #7782f8);
}

.Primary:hover {
  /* transition: all 0.15s ease-in-out; */
  background: var(--button-background-primary-hover, #5964da);
}

.Primary .Icon svg {
  fill: var(--button-text-color-primary, #ffffff);
}

.Primary .Icon svg path {
  stroke: var(--button-text-color-primary, #ffffff);
  fill: var(--button-text-color-primary, #ffffff);
}

/* SECONDARY BUTTON */
.Secondary {
  background: var(--button-background-secondary, transparent);
  color: var(--button-text-color-secondary, #181818);
  border: none;
}

.Secondary:hover {
  background: var(--button-background-secondary-hover, #dbdbdb);
  color: var(--button-text-color-hover-secondary, #181818);
}

.Secondary .Icon svg {
  fill: var(--button-text-color-secondary, #181818);
}

.Secondary .Icon svg path {
  stroke: var(--button-text-color-secondary, #181818);
  fill: var(--button-text-color-secondary, #181818);
}

/* SECONDARY BUTTON */
.Tertiary {
  background: var(--button-background-secondary-hover, #dbdbdb);
  color: var(--button-text-color-secondary, #181818);
  border: none;
}

.Tertiary:hover {
  background: var(--button-background-secondary-hover, #dbdbdb);
  color: var(--button-text-color-hover-secondary, #181818);
}

.Tertiary .Icon svg {
  fill: var(--button-text-color-secondary, #181818);
}

.Tertiary .Icon svg path {
  stroke: var(--button-text-color-secondary, #181818);
  fill: var(--button-text-color-secondary, #181818);
}

/* DANGER BUTTON */
.Danger {
  color: var(--button-text-color-danger, #ffffff);
  background: var(--button-background-danger, #ff5050);
}

.Danger:hover {
  background: var(--button-background-danger-hover, #cd1e1e);
}

.Danger .Icon svg {
  fill: var(--button-text-color-danger, #ffffff);
}

/* DISABLED BUTTON */
.Disabled {
  color: var(--button-text-color-disabled, #959595) !important;
  background: var(--button-background-disabled, #dbdbdb) !important;
  cursor: not-allowed;
}

.Disabled:hover {
  background: var(--button-background-disabled-hover, #dbdbdb) !important;
}

.Disabled .Icon svg {
  fill: var(--button-text-color-disabled, #959595) !important;
}

.Disabled .Icon svg path {
  stroke: var(--button-text-color-disabled, #959595) !important;
}

/* SUCCESS BUTTON */
.Success {
  color: var(--button-text-color-success, #ffffff);
  background: var(--button-background-success, #40c463);
}

.Success:hover {
  background: var(--button-background-success-hover, #2cb04f);
}

.Success .Icon svg {
  fill: var(--button-text-color-success, #ffffff);
}

.Success .Icon svg path {
  stroke: var(--button-text-color-success, #ffffff);
}

/* COMPLETE BUTTON */
.Complete {
  color: var(--button-text-color-success, #ffffff);
  background: var(--button-background-complete, #49a3e4);
}

.Complete:hover {
  background: var(--button-background-complete-hover, #358ac7);
}

.Complete .Icon svg {
  fill: var(--button-text-color-complete, #ffffff);
}

.Complete .Icon svg path {
  stroke: var(--button-text-color-complete, #ffffff);
}

/* XSMALL ICON BUTTON */
.XSmall {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: var(--button-border-radius-xsmall);
  padding: 0;
}

.XSmall .Icon,
.Small .Icon,
.Large .Icon {
  padding-bottom: 0;
}

.XSmall .Icon svg {
  width: 0.875rem;
  height: 0.875rem;
}

/* SMALL ICON BUTTON */
.Small {
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.Small .Icon svg path {
  fill: var(--button-text-color-primary, #ffffff);
  stroke: none;
}

/* LARGE ICON BUTTON */
.Large {
  padding: 0;
  height: 3.625rem;
  width: 3.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.Large svg {
  width: 1.5rem;
  height: 1.5rem;
}

/* MISC */
.MarginRight {
  margin-right: 0.5rem;
}

.MarginLeft {
  margin-left: 0.5rem;
}
