.Subtitle {
  text-transform: uppercase;
}

.CoachPicContainer {
  position: relative;
  overflow-y: hidden;
  margin: 1.2em -6.33vw;
}

.CoachPicContainer > img {
  width: 100%;
}

.Heading {
  font-size: 16px;
  margin-bottom: 0px;
}

.Text {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;

  letter-spacing: 0.005em;
}

.RatingContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 0.5rem;
}

.RatingValue {
  font-style: normal;
  font-weight: bold;
  font-size: 41.8px;
  line-height: 54px;
  letter-spacing: 0.005em;
  color: #e94811;
}

.RatingText {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.005em;
  max-width: 75%;
  color: #ffffff;
}

@media (min-width: 768px) {
  .CoachPicContainer {
    position: relative;
    height: 45vh;
    width: 100%;
    margin: 1.2em 0;
  }
  .CoachPicContainer > img {
    position: relative;
    width: 100%;
  }

  .Heading {
    font-size: 18px;
  }
}
