.DropDownContainer {
  position: absolute;
  left: 12.15rem;
  top: 1rem;
  font-size: 12px;
  z-index: 2;
  margin: 0;
}

.DropDownBox {
  background-color: #3c4148;
  border-radius: 7px;
  height: 55px;
  width: 250px;
  position: absolute;
  top: -55px;
  left: -54px;
  color: #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.DropDownButtons {
  margin: auto;
  transition: color 0.1s ease;
}

.DropDownButtons:hover {
  cursor: pointer;
  color: white;
}

.ArrowDown {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #3c4148;
}
