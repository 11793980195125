.Container {
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.69rem;
  text-align: center;
  border: 1px solid var(--color-accent-primary, #7782f8);
  background-color: var(--color-exercise-group-badge, #ffffff);
  border-radius: var(--border-radius-xsmall, 0.25rem);
}

.Container span {
  color: var(--color-exercise-group-badge-text, #404040);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}
