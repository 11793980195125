.List {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.Flex {
  display: flex;
  flex: 1;
}

.InputWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 1rem; */
  position: relative;
  margin-bottom: 1rem;
}

.InputWrapper:last-child {
  margin-bottom: 0rem;
}

.DeleteButton {
  margin-left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  border: none;
  background-color: transparent;
}

.DeleteIcon {
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  cursor: pointer;
  margin: 0;
}
