.FlexCenter {
  display: flex;
  align-items: center;
}

.CheckboxRoot {
  background-color: transparent;
  border: 1px solid var(--color-text-secondary, #959595);
  min-width: 1.5rem;
  min-height: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  padding-top: 0.375rem;
  border-radius: var(--border-radius-xsmall, 0.25rem);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.1s linear;
}

.CheckboxIndicator svg {
  fill: var(--color-text-on-accent, #ffffff);
}

.Label {
  padding-left: 0.875rem;
  color: var(--color-text-secondary, #959595);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.0175rem;
}

.CheckboxRoot[data-state='checked'] {
  border: 1px solid var(--color-accent-primary, #7782f8);
  background-color: var(--color-accent-primary, #7782f8);
}

.CheckboxLabel {
  color: var(--color-text-primary, #181818);
  margin-left: -0.275rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.18125rem */
  letter-spacing: -0.05625rem;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  cursor: pointer;
}
