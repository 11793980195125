.Container {
  display: flex;
  flex-wrap: row;
  min-height: 100%;
}

.LHSWrapper {
  width: 5.375rem;
}

.RHSWrapper {
  display: flex;
  width: calc(100% - 5.375rem);
}

.InnerRhsWrapper {
  width: 100%;
  padding: 1rem 1.25rem;
  position: relative;
  background: var(--color-background, #f3f4f5);
}

.ClientProfileLHSWrapper {
  width: 15.8125rem;
}

.ClientProfileRHSWrapper {
  display: flex;
  width: calc(100% - 15.8125rem);
}

@media (max-width: 900px) {
  .RHSWrapper {
    display: flex;
    width: unset;
  }
  .LHSWrapper {
    display: none;
  }
}
