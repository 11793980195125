.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 70rem;
  margin: 0 1rem;
  padding: 0 0.75rem;
}

.GraphHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: 100%;
  margin-top: 1em;
}

.whiteHeading {
  color: #ffffff;
}

.TabList {
  box-shadow: none;
  background: var(--color-card-background, #ffffff);
  padding: 0.5rem;
  border-radius: var(--border-radius-base, 0.5rem);
  margin: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  list-style-type: none;
  outline: none;
  width: 36%;
  min-width: 258px;
}

.Tab {
  padding: 0.5rem 1rem;
  white-space: nowrap;
  color: var(--color-text-primary, #181818);
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
  border-image: initial;
  border-bottom: none;
  bottom: 0px;
  position: relative;
  list-style: none;
  cursor: pointer;
  border-radius: var(--border-radius-base, 0.5rem);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex: 1 1 0%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.TabSelected {
  background: var(--color-accent-primary, #7782f8);
  color: var(--color-text-on-accent, #ffffff);
}

.BodyWeightGraphContainer {
  position: relative;
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  height: 200px;
}

.Graph {
  display: table-cell;
  position: relative;
}

.LineGraphWrapper {
  background-color: var(--color-card-background, #ffffff);
  padding: 10px 15px;
  border-radius: var(--border-radius-base, 0.5rem);
}

.NoDataTitle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #ffffff;
}

.EntriesWrapper {
  background-color: var(--color-card-background, #ffffff);
  border-radius: var(--border-radius-base, 0.5rem);
  position: relative;
  width: 20%;
  min-width: 12.875rem;
  height: 33.25rem;
}

.WeightsList {
  height: 94%;
  overflow: auto;
  margin: 0;
  list-style: none;
  padding: 1.5rem;
  padding-top: 0;
  margin-top: 1.5rem;
}

.EntriesTitle {
  position: sticky;
  top: 0;
  background-color: var(--color-card-background, #ffffff);
}

.EntriesTitle span {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.EntryListItem {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.EntriesTitle:nth-child(3) {
  margin-top: 1.5rem;
}

.EntryListItem p {
  padding: 0;
  margin: 0;
  margin-top: 0.5rem;
  color: var(--color-text-primary, #181818);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
}

.EntryListItem span {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.EntryListItem svg {
  width: 1.5rem;
  height: 1.5rem;
}

.EntryListItem svg path {
  stroke: var(--color-text-primary, #181818);
}

.EntrySelected p {
  font-weight: 600 !important;
  color: var(--color-accent-primary, #7782f8) !important;
}

.EntrySelected span {
  color: var(--color-accent-primary, #7782f8) !important;
}

.Divider {
  display: block;
  height: 2px;
  border: 0;
  border-top: 1px solid var(--color-divider, #dbdbdd);
  padding: 0;
  margin: 0;
  margin-top: 1rem;
}

.NoEntries {
  color: #ffffff;
  text-align: center;
}

.BottomContainer {
  display: flex;
  margin-top: 2.25rem;
  width: 100%;
}

.ProgressPhotosContainer {
  display: flex;
  flex-direction: column;
  width: 77%;
  margin-left: 3%;
}

.ProgressPhotosHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ProgressPhotosHeader div {
  padding: 0;
  margin-bottom: 10px;
}

.Heading {
  color: var(--color-text-primary, #181818);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.575rem */
  letter-spacing: -0.045rem;
  padding: 0;
  margin: 0;
}

.ProgressPhotoGrid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ProgressPhotoCard {
  width: 300px;
  border-radius: var(--border-radius-base, 0.5rem);
  background-color: var(--color-card-background, #ffffff);
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-bottom: 1rem;
  max-height: 500px;
}

.ImageContainer {
  border-radius: var(--border-radius-base, 0.5rem) var(--border-radius-base, 0.5rem) 0 0;
  display: block;
  height: 83%;
  width: auto;
  position: relative;
  overflow: hidden;
}

.ImageContainer > img {
  border-radius: var(--border-radius-base, 0.5rem) var(--border-radius-base, 0.5rem) 0 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.CategoryInfo {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 1rem 0;
}

.CategoryInfo > h5 {
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  margin: 0;
  padding: 0;
}

.CategoryInfo > p {
  color: var(--color-text-primary, #181818);
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  height: 48px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ClientNotesOverall {
  color: var(--color-text-primary, #181818);
}

@media (min-width: 1500px) {
  .Container {
    max-width: 85rem;
  }
}
