.NavItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-top: 4rem;
}

.ClientProfileNavItems {
  margin-top: 2rem;
}