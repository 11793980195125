.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44.4375rem;
}

.InnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.Content {
  margin-top: 2.5rem;
}

.Title {
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -0.105rem;
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  text-align: center;
}

.Description {
  margin: 0;
  margin-top: 1rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-secondary, #959595);
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  text-align: center;
}
