.tagContainer {
  display: flex;
  flex-wrap: wrap;
}

.tag {
  font-family: 'Industry-medium';
  margin: 0.2em;
  /* margin-right: 0; */
  padding: 0.5em;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e94811;
  border-radius: 8px;
  font-size: 0.8em;
  font-weight: 300;
  letter-spacing: 0.04em;
  color: #ffffff;
  text-transform: capitalize;
}
