.formWrapper {
  display: flex;
  flex-direction: column;
}

.InputWrapper:not(:first-child) {
  margin-top: 1rem;
}

.InputWrapper:nth-last-child(3) {
  display: flex;
  flex-direction: column;
}

.Label {
  margin: 0;
  display: inline-block;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-primary, #181818);
  margin-bottom: 0.5rem;
}

.SelectsRowWrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 1rem;
}

.SelectWrapper {
  min-width: 8.3125rem;
}

.submitBtnWrapper {
  padding-top: 1rem;
  text-align: center;
}

.submitBtn {
  border: 0;
  color: #fff;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-image: linear-gradient(#dd1a18, #f4730c);
}

.btnCentralize {
  display: flex;
  align-items: center;
  justify-content: center;
}

.addSetBtn {
  border: none;
  cursor: pointer;
  background: none;
}

.addSetBtn:focus {
  outline: none;
}

.addSetIcon {
  width: 1rem;
  height: 1.25rem;
  color: #3c4148;
  fill: currentColor;
  transition: all 0.1s ease;
}

.addSetBtn:hover .addSetIcon {
  color: silver;
}
