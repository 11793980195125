.CenterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.CenterContainer h3 {
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 2.1375rem */
  letter-spacing: -0.0225rem;
  margin-bottom: 1rem;
  color: var(--color-text-primary, #181818);
}

.NoAccountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1.75rem 1rem;
  text-align: center;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: all 0.1s ease;
}

.NoAccountHeading {
  padding: 0;
  margin: 0;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.105rem;
  margin-bottom: 0.5rem;
  color: var(--color-text-primary, #181818);
}

.NoAccountText {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-secondary, #959595);
  margin-bottom: 1.5rem;
  width: 25rem;
}

.NoStripeAccountSvg {
  width: 100%;
  height: 100%;
  max-width: 60px;
  max-height: 60px;
  margin-bottom: 1rem;
}

.NoStripeAccountSvg {
  fill: var(--color-icon-stroke, #404040);
}

.FormContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  max-width: 50%;
}

.FlexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.DashboardButton {
  margin-right: 0.5rem;
}

.FlexEndContainer {
  display: flex;
  justify-content: flex-end;
}

.Checkbox {
  width: 26px;
}

.Checkbox path {
  stroke: white;
}

.TableWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--table-background, #ffffff);
  margin-top: 2rem;
  height: 100vh;
  border-radius: var(--border-radius-base, 0.5rem);
}

.CopyCheckoutLinkButton {
  width: fit-content;
}

.CheckboxLabel a {
  color: var(--color-accent-primary, #7782f8);
}

.MarginTop {
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .NoAccountContainer {
    width: 100%;
  }

  .NoAccountText {
    padding: 0 !important;
  }
}
