.ProgressRoot {
  position: relative;
  overflow: hidden;
  background: var(--color-card-background, #ffffff);
  border: 1px solid var(--color-divider, #dbdbdb);
  border-radius: var(--border-radius-xlarge, 2rem);
  width: 100%;
  height: 0.375rem;
  transform: translateZ(0);
}

.ProgressIndicator {
  background-color: var(--color-accent-primary, #7782f8);
  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}
