.header {
  font-size: 29.03px;
  line-height: 1.32;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  color: #ffffff;
}

.feature {
  font-weight: normal;
  font-size: 11.67px;
  line-height: 13px;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  color: #ffffff;
}

.paragraph {
  color: #ffffff;
  font-family: 'Helvetica';
  max-width: 380px;
  position: relative;
  font-size: 14px;
}

.ce_orange {
  color: #e94811;
}

.header span {
  color: #e94811;
}

@media (min-width: 768px) {
  .header {
    font-size: 2.04rem;
  }

  .feature {
    font-size: 0.77em;
  }
}
