.Container {
  display: flex;
  margin-top: 1rem;
  align-items: center;
  border-radius: var(--border-radius-base, 0.5rem);
  padding: 1rem;
  background-color: var(--color-card-background, #ffffff);
  box-shadow: var(--color-box-shadow, 0px 4px 20px 0px rgba(0, 0, 0, 0.1));
  z-index: 999;
}

.Icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  border-radius: var(--border-radius-xsmall, 0.25rem);
  background-color: var(--color-accent-primary, #7782f8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.SuccessIcon {
  background-color: var(--color-success, #40c463);
}
.SuccessIcon svg path {
  fill: var(--button-text-color-success, #ffffff);
}

.ErrorIcon {
  background-color: var(--color-danger, #ff5050);
}
.ErrorIcon svg {
  height: 21px;
  width: 21px;
}
.ErrorIcon svg path {
  fill: var(--button-text-color-danger, #ffffff);
}

.Message {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
}
