.Container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.ToolBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.SearchExerciseLibrary {
  display: flex;
  align-items: center;
  width: 12.5rem;
}

.SearchExerciseLibrary form {
  width: fit-content;
  height: fit-content;
}

.SpinnerWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 1rem;
}

.NoExercises {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.LinkVideoButton {
  color: var(--color-danger, #ff5050);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.03375rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

.LinkVideoButton:hover {
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.BulkActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
