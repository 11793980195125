.Auth {
  height: 100vh;
}

.AuthContainer {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-background, #f3f4f5);
}

.AuthHeading {
  margin: 0 auto;
  color: var(--color-text-primary, #181818);
  text-align: center;
}

.AuthSubHeading {
  margin: 0;
  padding: 0;
  margin-top: 1rem;
  text-align: center;
  width: 50%;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  color: var(--color-text-primary, #181818);
  max-width: 32.8125rem;

  .Bold,
  a {
    text-decoration: none;
    font-weight: 500;
    font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
    color: var(--color-text-primary, #181818);
  }
}

.AuthWrapper {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.Header {
  position: fixed;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.AuthButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.UserForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 200;
  margin-top: 3.5rem;
}

.UserFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Logo {
  z-index: 1;
}

::placeholder {
  color: #868a92;
}

.NotAMemberText {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  text-transform: uppercase;
}

.SignupButton {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  color: var(--color-accent-primary, #7782f8);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  text-transform: uppercase;
  margin-left: 0.5rem;
  transition: all 0.1s linear;
}

.SignupButton:hover {
  cursor: pointer;
  color: var(--color-accent-primary-hover, #5964da);
}

.LoadingButton {
  width: 50%;
  height: 50px;
  background: transparent linear-gradient(137deg, #d90f1b 0%, #ec4219 100%) 0% 0% no-repeat padding-box;
  border-radius: 7px;
  outline: none;
  border: none;
  font-size: 18px;
  color: #f2f3f5;
  align-self: center;
  cursor: pointer;
  margin-top: 36px;
  transition: all 0.1s linear;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: #3c4148 !important;
  background-image: none !important;
  color: #cacbd5 !important;
}

.LoadingButton:disabled {
  cursor: auto;
}
