.Row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.InputWrapper {
  width: 20.625rem;
  margin-bottom: 1.5rem;
}

.Button {
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: fit-content;
  margin-top: 1.5rem;
}
