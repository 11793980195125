.Buttons {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.Buttons button {
  width: fit-content;
}
