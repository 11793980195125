.wrapper {
  width: 100%;
}

.inputWrapper {
  margin-bottom: 1rem;
}

.btnWrapper {
  margin-top: 2rem;
}
