.FunnelMain {
  background-color: #1d1f21;
}
.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.SectionsWrapper {
  width: 100%;
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
  padding: 4.77em 6.33vw;
  padding-bottom: 0;
  overflow: hidden;
}
.Section {
  padding-bottom: 4.77em;
  color: #ffffff;
}
.MainGrid {
  max-width: 1920px;
  display: block;
}

@media (min-width: 992px) {
  .SectionsWrapper {
    padding: 0em 0 0em 0;
  }

  .MainGrid {
    display: grid;
    grid-template-columns: 2fr 7fr;
    grid-gap: 3em;
    position: relative;
    /* padding: 4.77em 8.33vw; */
    padding-left: 8.33vw;
    padding-top: 4.77em;
    padding-bottom: 0;
  }

  .Section {
    padding-bottom: 6.54em;
    padding-right: 8.77vw;
  }
}
