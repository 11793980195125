.NotificationCentre {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: var(--color-card-background, #ffffff);
}

.NotificationHeader {
  border: 0px none;
  width: 100%;
  padding-top: 1.5rem;
  position: sticky;
  margin-bottom: 1.5rem;
  z-index: 10;
  background-color: inherit;
  top: 0;
}

.NotificationHeader h5 {
  color: var(--color-text-primary, #181818);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.8375rem */
  letter-spacing: -0.0875rem;
  margin: 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-divider, #dbdbdb);
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
}

.NotificationList {
  max-height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.NotificationSubList {
  display: flex;
  flex-direction: column;
}

.NotificationContent {
  display: flex;
}

.NotificationSection {
  display: flex;
  flex-direction: column;
}

.NoNotifications {
  display: flex;
  padding: 0;
  margin: 0;
  color: var(--color-text-primary, #181818);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  text-transform: uppercase;
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
}

.SectionHeading {
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  text-transform: uppercase;
  color: var(--color-text-primary, #181818);
  margin-bottom: 0.5rem;
}

.Separator {
  height: 1px;
  margin: 1.5rem 0;
  background: var(--color-divider, #dbdbdb);
}

.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
