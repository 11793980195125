.Form {
  display: block;
  width: 24.125rem;
}

.FormElement {
  margin-top: 1rem;
}

.PlanNameFormElement {
  width: 100%;
  margin-top: 0;
}

.CurrencyFormElement {
  width: 38%;
}

.PriceFormElement {
  width: 58%;
}

.IntervalCountFormElement {
  width: 58%;
}

.PaymentCycleFormElement {
  width: 38%;
}

.Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ButtonContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 3rem;
}
