.FormContainer {
  width: 100%;
  margin: 0 auto;
  max-width: 22rem;
}

.InputWrapper {
  margin-bottom: 1rem;
}

.ResetPasswordWrapper {
  display: flex;
  margin-top: 1rem;
}

.ResetPassword {
  text-decoration: none;
  transition: color 0.1s ease-in-out;
}

.ResetPassword span {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 1rem */
  text-transform: uppercase;
  color: var(--color-text-primary, #181818);
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
}

.ResetPassword:hover span {
  color: var(--color-accent-primary, #7782f8);
}

.ButtonWrapper {
  margin-top: 2.5rem;
}
