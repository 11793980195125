.wrapper {
  width: 100%;
}

.innerWrapper {
  border: 0;
  height: 3rem;
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  border-radius: 0.4375rem;
  background-color: #3c4148;
  border: 0.125rem solid transparent;
  transition: border-color 0.1s ease;
}

.errorInnerWrapper {
  border-color: #d90f1b;
}

.input {
  border: 0;
  width: 100%;
  height: 100%;
  outline: none;
  color: #cacbd5;
  padding: 0 0.25rem;
  font-size: 1.125rem;
  margin-right: 0.625rem;
  background: transparent;
}

.iconWrapper {
  display: flex;
  flex-basis: 1.75rem;
  margin-top: -0.25rem;
  align-items: flex-start;
}

.error {
  display: block;
  color: #d90f1b;
  text-align: right;
  margin-top: 0.25rem;
  text-transform: uppercase;
}
