.ToggleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.ToggleText {
  margin-left: 10px;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.3125rem */
  letter-spacing: -0.0625rem;
  color: var(--color-text-primary, #181818);
  display: flex;
  align-items: center;
}
