.cardContainer {
  background-color: #25292c;
  color: white;
  border-radius: 16px;
  max-width: 300px;
  box-shadow: 0.5px 0.5px 16px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  height: 100%;
}

.cardContent {
  padding: 1em;
  position: relative;
}

.cardContainer > img {
  width: 100%;
  border-radius: 16px 16px 0 0;
}

.cardTitle {
  line-height: 1.32;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-size: 1.1em;
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  /* align-items: center; */
}

.cardTitle span {
  font-size: 0.6em;
  font-weight: 400;
  line-height: 2.5;
  /* line-height: normal; */
}

.coachName {
  font-weight: 100;
  letter-spacing: 0.04em;
  margin: 10px 0;
  font-family: 'Industry-medium';
}

.cardContent p {
  font-family: 'Helvetica';
  font-size: 0.83em;
  letter-spacing: 0.04em;
}

@media (min-width: 768px) {
  .cardContainer {
    font-size: 16px;
    min-width: 300px;
    /* height: auto; */
  }
}
