.Form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.InputWrapper {
  display: flex;
  justify-content: center;
  width: 14.8125rem;
}
