.Dropzone {
  border: 1px dashed var(--color-divider, #dbdbdb);
  border-radius: var(--border-radius-base, 0.5rem);
  padding: 0.75rem 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 130px;
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.Dropzone p {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.Active {
  border: 1px dashed var(--color-accent-primary, #7782f8) !important;
}
