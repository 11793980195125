.Wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-card-background, #ffffff);
  border-radius: var(--border-radius-large, 1rem);
  padding: 1rem;
  max-width: 45.4375rem;
  max-height: 27.8125rem;
  margin-top: 2rem;
}

.TopLayer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Title {
  margin: 0;
  padding: 0;
  color: var(--color-accent-primary);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.44375rem */
  letter-spacing: -0.0275rem;
}

.TableWrapper {
  width: 100%;
  box-sizing: border-box;
  border-spacing: 0 1rem;
}

.ScrollableContainer {
  flex: 1;
  overflow-y: auto;
  background-color: #ffffff;
}

.TableWrapper tbody {
  width: 100%;
}

.TableHeader {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: var(--color-card-background, #ffffff);
}

.TableHeader th {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}

.ColumnHeader {
  color: var(--color-text-primary, #181818);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.6875rem */
  text-transform: uppercase;
  text-align: left;
}

/* Doesn't sum up to 100% because this helps keep a space between the second and the third row */
.TableWrapper th:nth-child(1),
.TableWrapper tr:nth-child(1) {
  width: 15%;
}

.TableWrapper th:nth-child(2),
.TableWrapper tr:nth-child(2) {
  width: 50%;
}

.TableWrapper th:nth-child(3),
.TableWrapper tr:nth-child(3) {
  width: 30%;
}

.Item {
  color: var(--color-text-primary, #181818);
  text-transform: uppercase;
  color: var(--color-text-primary, #181818);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.6875rem */
  text-transform: uppercase;
}

.Position {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.6875rem */
  text-transform: uppercase;
}

.ProgramWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.OverviewWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Thumbnail {
  width: 2.25656rem;
  height: 2.25656rem;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.GrowthWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 100px;
}

.GrowthCount {
  font-size: 10px;
  margin-right: 20px;
  font-family: 'Industry-Medium';
}

.GrowthIcon {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  margin-right: 3px;
}

.SubCount {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-size: 18px;
}

.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-bottom: 25px;
}
