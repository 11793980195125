.ContentContainer {
  position: relative;
  height: 79vh;
  display: flex;
  flex-direction: column;
  min-width: 32rem;
  max-width: 1084.5px;
}

.ModalHeader {
  color: var(--color-text-primary, #181818) !important;
}

.ModalHeader h5 {
  margin: 0;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.8375rem */
  letter-spacing: -0.0875rem;
  font-family: var(--font-family-medium, 'PlusJakartaDisplay-Medium', sans-serif);
}

.ModalHeader p {
  padding: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  margin-top: 0.5rem;
}

.ModalInnerContainer {
  overflow-x: hidden;
  height: 89%;
  overflow-y: scroll;
  display: flex;
  box-sizing: border-box;
  padding-right: 0.25rem;
}

.ButtonGroup {
  width: 100%;
  padding: 1rem 0 2rem;
  z-index: 1;
  background-color: var(--color-card-background, #ffffff);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.ButtonWrapper {
  border-radius: var(--border-radius-base, 0.5rem);
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  background-color: var(--color-background, #f3f4f5);
}

.ButtonWrapper button {
  width: fit-content;
}

.DayButton:hover,
.CloseButton:hover {
  background-color: rgba(102, 101, 101, 0.746);
  transition: all 0.1s linear;
  color: white;
}
