.swiper-slide {
  width: auto !important;
  height: auto !important;
}

.swiper-horizontal > .swiper-pagination-progressbar {
  height: 6px;
  top: auto !important;
  bottom: -2.385rem !important;
  background-color: #ffffff;
  border-radius: 50px;
}

:root {
  --swiper-theme-color: linear-gradient(92.54deg, #da0c1a 0.73%, #f8820a 117.24%) !important;
}

.swiper {
  overflow: visible !important;
}

.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  visibility: visible;
}

.swiper-button-next,
.swiper-button-prev {
  color: #ffffff !important;
  background: #1d1f21;
  padding: 30px;
  border-radius: 40px;
  visibility: hidden;
  transition: all ease-in-out 0.1s;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-prev {
  left: 0 !important;
}

@media (min-width: 768px) {
  .swiper-horizontal > .swiper-pagination-progressbar {
    width: 40% !important;
  }
}
