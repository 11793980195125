.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
