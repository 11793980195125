.Container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Card {
  width: 100%;
  padding: 1.5rem;
  margin-top: 2rem;
  border-radius: var(--border-radius-base, 0.5rem);
  max-width: 50rem;
  background-color: var(--color-card-background, #ffffff);
}
