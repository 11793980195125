.formWrapper {
  background-color: transparent;
  display: flex;
  flex: 1;
  border: 1px solid var(--color-input-border-active, #959595);
  border-radius: var(--border-radius-base, 0.5rem);
  margin-left: 1.5rem;
}

.MessageInput,
.MessageInputUpload {
  display: flex;
  width: 100%;
  position: relative;
}

.MessageInput textarea,
.MessageInputUpload textarea {
  flex: 1;
  background-color: transparent;
  border: 0;
  border-left: unset;
  border-bottom: unset;
  outline: none;
  padding: 1.5rem 1rem;
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  max-height: 260px;
  overflow-y: auto;
  resize: none;
  position: relative;
  transition: all 0.1s linear;
}

.MessageInputUpload textarea {
  padding-bottom: 120px;
}

.ButtonsContainer {
  flex: 1;
  background-color: transparent;
  border: 0;
  outline: none;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}

.SendButton svg,
.AttachButton svg,
.EmojiButton svg {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  stroke: var(--color-icon-stroke, #404040);
  transition: all 0.1s linear;
}

.SendButton svg {
  height: 1.8rem;
  width: 1.8rem;
  fill: var(--color-icon-stroke, #404040);
}

.AttachButton,
.EmojiButton,
.SendButton {
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: var(--border-radius-xsmall, 0.25rem);
}

.AttachButton:hover,
.EmojiButton:hover,
.SendButton:hover {
  background-color: var(--color-icon-wrapper-hover, #dbdbdb);
}

.Divider {
  border-left: 2px solid var(--color-divider, #dbdbdb);
  height: 1.375rem;
  padding: 0 4px;
  margin-left: 0.25rem;
}

.PreviewUpload {
  position: absolute;
  bottom: 0;
  left: 1.5em;
  border-radius: 0.5rem;
  overflow: hidden;
}

.PreviewUpload img {
  height: 80px;
}

.ProgressWrapper {
  height: 0.25rem;
  position: relative;
  max-width: 18.75rem;
  margin: 2rem auto 0;
  border-radius: 0.25rem;
  background-color: #171a1c;
}

.ProgressBar {
  top: 0;
  left: 0;
  height: 100%;
  max-width: 100%;
  position: absolute;
  border-radius: 0.25rem;
  transition: all 0.1s ease;
  background-color: var(--color-accent-primary, #7782f8);
}
