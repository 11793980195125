.sectionGrid > img {
  width: 106.33%;
}

.desktop {
  display: none;
}

.mobile {
  display: block;
}

@media (min-width: 768px) {
  .sectionGrid {
    grid-template-columns: repeat(6, 1fr);
    display: grid;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }

  .sectionGrid > img {
    width: 100%;
    grid-column: span 4;
  }

  .sectionGrid > div:first-child {
    grid-column: span 2;
  }

  .gridCol2 {
    grid-row: 1;
    order: 2;
  }
  .gridCol1 {
    order: 1;
    grid-row: 1;
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}
