.DropDownContainer {
  background-color: var(--color-accent-primary, #7782f8);
  transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  z-index: 3;
  height: fit-content;
  min-width: 8.3125rem;
  padding: 0.75rem 1rem;
  margin: 0;
  border-radius: var(--border-radius-base, 0.5rem);
  list-style: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.DropDownContainer[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropDownContainer[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropDownContainer[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropDownContainer[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropDownButton {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 1rem */
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  transition: 0.1s ease-in-out;
  color: var(--color-text-on-accent, #ffffff);
  padding: 0.75rem 0;
  border-radius: var(--border-radius-base, 0.5rem);
  user-select: none;
  outline: none;
}

.DropDownButton[data-highlighted] {
  transition: 0.1s ease-in-out;
  cursor: pointer;
  background-color: var(--color-accent-primary-hover, #5964da);
}

.LineRule {
  height: 0.0625rem;
  border-bottom: 1px solid var(--color-divider, #dbdbdb);
  opacity: 0.5;
  margin: 0.25rem 0;
}

.WithIcon {
  padding: 0.75rem 0.5rem;
}

.Icon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Icon svg {
  width: 1.125rem;
  height: 1.125rem;
}

.Icon svg path {
  fill: var(--color-text-on-accent, #ffffff);
}

.MarginRight {
  margin-right: 0.5rem;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
