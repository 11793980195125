.CTAContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  font-size: 14px;
  padding: 10.54rem 4.33vw;
}

.CTAContainer button {
  font-weight: 600;
  max-width: fit-content;
  font-size: 1.1em;
  font-family: 'Industry-medium';
  text-transform: uppercase;
  padding: 1.5em 2em;
  align-items: center;
  line-height: 0.9;
  border-radius: 4px;
  border: 1px solid #e94811;
  margin-top: 50px;
  height: unset;
}

.CTAContainer > p {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 11.667px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.005em;
  max-width: 200px;
  color: #ffffff;
  align-self: center;
  margin-bottom: 25px;
}

.CTAContainer h2 {
  font-size: 2.9em;
  line-height: 1.32;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .CTAContainer {
    padding: 11.31rem 4.33vw;
  }
  .CTAContainer > p {
    max-width: 400px;
    font-size: 1em;
  }

  .CTAContainer button {
    font-size: 1em;
  }

  .CTAContainer h2 {
    font-size: 4.07em;
  }
}
