.Page {
  padding: 0 1rem;
  background: var(--color-background, #171a1c);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
}

.Heading {
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 3.325rem */
  letter-spacing: -0.105rem;
  color: var(--color-text-primary, #181818);
  width: 100%;
  text-align: center;
  margin-top: 120px;
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
}

.Page ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
}

.Page ul li {
  width: 337px;
  height: 41px;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 1.9rem */
  letter-spacing: -0.02rem;
  width: 100%;
  max-width: 780px;
  text-align: left;
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.Page ul li {
  margin-top: 64px;
}

.Page ul li:first-child {
  margin-top: 0;
}

.CardContainer {
  color: white;
  border-radius: 16px;
  min-width: 327px;
}

.FunnelCardContainer {
  background-color: #25292c;
  box-shadow: 6px 6px 56px 0px #00000080;
}

.ImageContainer {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: 250px;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
  border-radius: 16px 16px 0 0;
}

.ImageContainer img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  min-height: 100%;
  transform: translateX(-50%);
  margin-left: 50%;
  object-fit: cover;
}

.CardContent {
  position: relative;
}

.Pricing {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 1.9rem */
  letter-spacing: -0.02rem;
  color: var(--color-accent-primary, #7782f8);
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  margin: 0;
}

.CardContent h5 {
  font-size: 18px;
  color: white;
  margin-top: 5px;
  margin-bottom: 20px;
}

.CardContent h6 {
  font-family: 'Industry-Medium';
  font-size: 14px;
  color: lightgray;
  margin: 0;
}

.Title {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 1.9rem */
  letter-spacing: -0.02rem;
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  margin: 0;
  margin-top: 1.5rem;
}

.Title span {
  font-size: 0.6em;
  font-weight: 400;
  line-height: 11.77px;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.CategoryContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
}

.Category {
  margin-right: 0.5em;
  padding: 8px;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e94811;
  border-radius: 10px;
  font-size: 0.8em;
  font-weight: 300;
  letter-spacing: 0.04em;
}

.Description {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-primary, #181818);
}

.Liststyle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border: 2px solid var(--color-accent-primary, #7782f8);
  box-sizing: border-box;
  border-radius: var(--border-radius-small, 0.25rem);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-accent-primary, #7782f8);
  text-align: center;
  margin-right: 15px;
}

.ListstyleComplete {
  background: var(--color-accent-primary, #7782f8);
}

.ListstyleComplete img {
  width: 15.2px;
}

.Disclaimer {
  width: 100%;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  color: var(--color-text-primary, #181818) !important;
  margin-top: 28px;
}

.Disclaimer a {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  color: var(--color-text-primary, #181818) !important;
  text-decoration-line: underline;
}

.Header {
  position: absolute;
  width: 99%;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.AuthButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.UserForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 200;
}

.UserFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Logo {
  z-index: 1;
}

.Logo a {
  cursor: pointer;
}

::placeholder {
  color: #868a92;
}

.signPreText {
  color: white;
  font-size: 15px;
}

.SignupButton {
  color: #e94811;
  font-size: 15px;
  margin-left: 5px;
}

.SignupButton:hover {
  cursor: pointer;
  color: #f47c0b;
}

.modalContainer {
  margin: auto;
  position: relative;
  background: #171a1c;
  border-radius: 0.5rem;
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.787);
}

.modalContainerNoPadding {
  margin: auto;
  position: relative;
  background: #171a1c;
  border-radius: 0.5rem;
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.787);
}

.ModalClose {
  top: 0.75rem;
  right: 0.75rem;
  position: absolute;
}

.ModalClose img {
  height: 12px;
  cursor: pointer;
  z-index: 600;
}

.ModalLargeText {
  margin: 0;
  color: white;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  padding: 1em 1em 0em 1em;
}

.ModalSaveIcon {
  text-align: center;
}

.ModalSaveIcon img {
  height: 60px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 12px;
  box-shadow: 0px 5px 20px black;
}

.ModalSmallText {
  color: white;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
}

.ModalButtons {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: #252525;
  padding: 18px 15px;
}

.ModalCancel {
  margin-right: 7px;
  border-radius: 0;
  border: 0;
  width: 80px;
  font-weight: bold;
  font-size: 14px;
  color: white;
  background-color: transparent;
  cursor: pointer;
  letter-spacing: 1px;
  outline: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ModalCancel:hover {
  background-color: rgba(215, 57, 39, 0.287);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.ModalDelete {
  margin-left: 7px;
  border-radius: 0;
  letter-spacing: 1px;
  padding: 8px;
  width: 80px;
  border: 0;
  color: white;
  font-weight: bold;
  font-size: 14px;
  background-color: #d73a27;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.1);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.ModalDelete:hover {
  background-color: rgb(211, 78, 63);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@media (min-width: 730px) {
  .Page ul {
    width: 780px;
    margin-bottom: 50px;
  }

  .CardContainer {
    border-radius: 16px;
    width: 100%;
    display: flex;
  }

  .CardContainerHeight {
    height: 208px;
  }

  .ImageContainer {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: 50%;
    height: 100%;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
  }

  .ImageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    min-height: 100%;
    transform: none;
    width: 100%;
  }

  .CardContent {
    position: relative;
    width: 50%;
  }

  .CardContentPadding {
    padding: 1em;
  }

  .Title span {
    font-size: 0.6em;
    font-weight: 400;
    line-height: 11.77px;
    padding-bottom: 5px;
  }

  .NumPrograms {
    display: none;
  }

  .CategoryContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
  }
}

@media (max-width: 800px) {
  .CardContent h6 {
    margin-bottom: 10px;
  }

  .Page {
    width: 100vw;
    padding: 0;
  }

  .Page h1 {
    font-size: 60px;
    line-height: 54.17px;
  }

  .Page ul {
    padding: 0 60px;
    width: 100vw;
  }

  .Title span {
    display: none !important;
  }

  .NumPrograms {
    display: flex;
    font-size: 0.6em;
    font-weight: 400;
    line-height: 11.77px;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  .ImageContainer img {
    min-height: unset;
    transform: unset;
    margin-left: 0;
    width: 358px;
    height: 250px;
  }
}

@media (max-width: 480px) {
  .Page {
    padding: 0 1rem;
    padding-bottom: 30px;
  }

  .Page h1 {
    font-size: 42px !important;
    line-height: 54.17px !important;
  }

  .Page ul {
    padding: 0 !important;
    width: fit-content;
  }

  .Page ul li {
    font-size: 24px;
  }

  .Logo img,
  .Logo a {
    height: 26px;
  }

  .Liststyle {
    min-width: 36px;
    min-height: 36px;
  }

  .Title {
    font-size: 24px;
  }
}
