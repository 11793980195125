.Container {
  display: flex;
  flex-direction: row;
  width: 180px;
}

.ExerciseType {
  color: var(--color-text-primary, #181818);
  font-size: 14px;
  text-transform: uppercase;
}

.Set {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 0px;
  flex-wrap: wrap;
}

.Separator {
  width: 4px;
  height: 4px;
  border-radius: 20px;
  margin: 0 5px;
  background-color: var(--color-divider, #dbdbdb);
}

.Text {
  color: var(--color-text-primary, #181818);
  font-size: 10px;
  text-transform: uppercase;
}
