.Page {
  padding: 0 1rem;
  padding-top: 120px;
  background: var(--color-background, #f3f4f5);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 675px;
}

.PageTitle {
  color: var(--color-text-primary, #181818);
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 3.325rem */
  letter-spacing: -0.105rem;
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  width: 100%;
  text-align: center;
}

.FormContainer {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.Flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-top: 2.5rem;
}

.FormContainer h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 1.9rem */
  letter-spacing: -0.02rem;
  color: var(--color-text-primary, #181818);
  margin: 0;
}

.FormContainer p {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  color: var(--color-text-primary, #181818);
  text-align: left;
  margin: 2rem 0;
}

.Page ul li {
  margin-top: 64px;
}

.Page ul li:first-child {
  margin-top: 0;
}

.CardContainer {
  background-color: #25292c;
  color: var(--color-text-primary, #181818);
  border-radius: 16px;
  max-width: 350px;
}

.ImageContainer {
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: 250px;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: relative;
  max-width: 100%;
  border-radius: 0 16px 16px 0;
}

.ImageContainer img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  min-height: 100%;
  transform: translateX(-50%);
  margin-left: 50%;
}

.CardContent {
  padding: 1em;
  position: relative;
}

.Title {
  font-family: Industry, sans-serif;
  line-height: 26.32px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
}

.Title span {
  font-size: 0.6em;
  font-weight: 400;
  line-height: 11.77px;
  padding-bottom: 5px;
}

.CategoryContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
}

.Category {
  margin-right: 0.5em;
  padding: 8px;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #e94811;
  border-radius: 10px;
  font-size: 0.8em;
  font-weight: 300;
  letter-spacing: 0.04em;
}

.Description {
  font-size: 0.83em;
  letter-spacing: 0.04em;
}

.Disclaimer {
  width: 100%;
  text-align: left;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 12.8px;
  line-height: 15px;
  color: var(--color-text-primary, #181818);
  margin-top: 28px;
}

.Disclaimer a {
  text-decoration: underline;
  color: var(--color-text-primary, #181818);
}

.Header {
  position: fixed;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.AuthButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.UserForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 200;
}

.UserFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Logo {
  z-index: 1;
}

.signPreText {
  color: var(--color-text-primary, #181818);
  font-size: 15px;
}

.SignupButton {
  color: var(--color-text-primary, #181818);
  font-size: 15px;
  margin-left: 5px;
}

.SignupButton:hover {
  cursor: pointer;
  color: var(--color-text-primary, #181818);
}

.playStoreBadges {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.playStoreBadges img {
  height: 60px;
}

.PerkOfMemberText {
  text-align: center !important;
}

.CountDown {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  margin: 1.5rem 0 2rem;
  text-align: center;
}

.CountDown span {
  color: var(--color-accent-primary, #7782f8);
}

.CountdownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CountdownContainer button {
  width: 300px;
}

.Liststyle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border: 2px solid var(--color-accent-primary, #7782f8);
  box-sizing: border-box;
  border-radius: var(--border-radius-small, 0.25rem);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-accent-primary, #7782f8);
  text-align: center;
  margin-right: 15px;
}

@media (min-width: 730px) {
  .FormContainer {
    max-width: 780px;
    margin-bottom: 7rem;
  }

  .CardContainer {
    background-color: #25292c;
    color: var(--color-text-primary, #181818);
    border-radius: 16px;
    max-width: 100%;
    height: 208px;
    display: flex;
  }

  .ImageContainer {
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    width: 50%;
    height: 100%;
    background: none;
    opacity: 1;
    border: 0;
    margin: 0;
    padding: 0;
    position: relative;
    border-radius: 16px 0 0 16px;
  }

  .ImageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: 0;
    border: none;
    margin: auto;
    display: block;
    min-height: 100%;
    transform: none;
    width: 100%;
  }

  .CardContent {
    padding: 1em;
    position: relative;
    width: 50%;
  }

  .Title {
    line-height: 26.32px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    margin: 0;
  }

  .Title span {
    font-size: 0.6em;
    font-weight: 400;
    line-height: 11.77px;
    padding-bottom: 5px;
  }

  .CategoryContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
  }

  .Category {
    margin-right: 0.5em;
    padding: 8px;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #e94811;
    border-radius: 10px;
    font-size: 0.8em;
    font-weight: 300;
    letter-spacing: 0.04em;
  }

  .Description {
    font-size: 0.83em;
    letter-spacing: 0.04em;
  }
}

@media (max-width: 480px) {
  .PageTitle {
    font-size: 48px !important;
  }
  .FormContainer h4 {
    font-size: 17px;
    text-align: center;
    margin-top: 0;
  }
  .FormContainer p {
    font-size: 14px;
    text-align: left;
    margin: 1rem 0;
  }

  .Logo img,
  .Logo a {
    height: 26px;
  }

  .CountDown {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 35px;
  }
}

@media (max-width: 420px) {
  .PageTitle {
    font-size: 40px !important;
  }
  .FormContainer h4 {
    font-size: 16px;
    text-align: center;
    margin-top: 0;
  }
  .FormContainer p {
    font-size: 14px;
    margin: 1rem 0;
  }
  .playStoreBadges {
    margin-top: 8px;
  }

  .playStoreBadges img {
    height: 50px;
  }
}
