.MessageText {
  max-width: 500px;
  margin: 0;
  line-height: 22px;
  border-radius: 25px;
  position: relative;
  color: var(--color-text-primary, #181818);
  text-align: left;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.Badge {
  display: flex;
  height: 100%;
  align-items: flex-start;
}

.DisplayNameReceived,
.DisplayNameSent {
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  position: relative;
  color: var(--color-text-primary, #181818);
  text-align: left;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  margin-bottom: 0.31rem;
}

.DisplayNameSent {
  text-align: right;
}

.Sent,
.Received {
  display: flex;
  align-items: center;
  transition: all 0.1s linear;
  border-radius: var(--border-radius-base, 0.5rem);
  position: relative;
  padding: 0.5rem;
}

.Sent:hover,
.Received:hover {
  background-color: var(--color-background-secondary, #dbdbdb);
}

.Sent {
  flex-direction: row-reverse;
}

.MediaOptions {
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
  align-items: center;
  background-color: #191d21;
  border-radius: 0.5rem;
  height: 30px;
  width: 40px;
  justify-content: center;
  cursor: pointer !important;
  transition: all 0.1s linear;
}

.MediaOptions:hover svg {
  fill: #ffffff;
  transition: all 0.1s linear;
}

.MediaOptions a {
  display: flex;
  align-items: center;
}

.Content {
  position: relative;
  width: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  background: #55575554;
  border: 2px solid #555755;
  margin-bottom: 5px;
}

.Content img {
  width: 100%;
  height: 100%;
}

.Content {
  cursor: zoom-in;
}

.Content video {
  cursor: initial !important;
  width: 100%;
}

.TimeNameContainerSent,
.TimeNameContainerReceived {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.TimeNameContainerSent {
  flex-direction: row-reverse;
}

.TimeNameContainerSent .Time {
  margin-right: 8px;
}

.TimeNameContainerReceived .Time {
  margin-left: 8px;
}

.Time {
  color: var(--color-text-secondary, #959595);
  align-self: baseline;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.125rem */
  text-transform: uppercase;
}

.MessageContentSent,
.MessageContentReceived {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}

.MessageContentReceived {
  align-items: flex-start;
  margin-left: 10px;
}

.DownloadWrapper {
  position: absolute;
  right: 50px;
  top: 50px;
  display: flex;
  align-items: center;
}

.DownloadWrapper a {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.DownloadWrapper a svg {
  transition: all 0.1s linear;
  fill: var(--color-icon-stroke, #404040);
}

.DownloadWrapper a:hover svg {
  fill: var(--color-icon-wrapper-hover, #dbdbdb);
  transition: all 0.1s linear;
}

.DownloadWrapper img {
  height: 18px;
  cursor: pointer;
  z-index: 600;
}

.ImageContainerModal {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImageContainerModal img {
  padding: 0 50px;
  height: auto;
  width: 80%;
}

.PlayerWrapper {
  position: relative;
  padding-top: 56.25%;
  height: 200px;
  width: 100%;
}

.ReactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}
