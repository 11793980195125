.wrapper {
  height: fit-content;
  position: relative;
}

.Day {
  width: 17.5rem;
  padding: 1rem;
  padding-right: 0.5rem;
  padding-top: 0;
  margin-bottom: 30px;
  position: sticky;
  margin-right: 12px;
  z-index: 1;
  transition: all 0.1s linear;
  background-color: var(--color-card-background, #ffffff);
  border-radius: var(--border-radius-large, 1rem);
  max-height: 37.1875rem;
  overflow-y: hidden;
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  padding: 1rem 0;
  margin-right: -0.5rem;
}

.DayHeading {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 105%; /* 1.575rem */
  letter-spacing: -0.045rem;
  text-transform: uppercase;
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  margin: 0;
  padding: 0;
  margin-bottom: 0.44rem;
}

.DayName {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.DayContent {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
  max-height: 32rem;
}

.Content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 1rem;
}

.DayContentQuickEdit {
  position: relative;
  padding-bottom: 65px;
}

.Button {
  color: white;
  background-color: transparent;
  border: 0;
  outline: none;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 600;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  transition: color 0.1s ease;
}

.Button:hover {
  cursor: pointer;
  color: #d73a27;
}

.Title {
  display: flex;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
}

.Workouts {
  justify-content: left;
  margin: 0;
}

.WorkoutsQuickEdit,
.WorkoutsQuickEditFeedback {
  display: flex;
  margin: 0;
  width: fit-content;
  overflow-y: scroll;
}

.WorkoutsQuickEditFeedback {
  max-width: 410px;
}

.Divider,
.DividerNotes {
  top: 0;
  position: sticky;
  margin: 0 14px 0 18px;
}

.Divider div,
.DividerNotes div {
  border-left: 2px solid #3c4147;
  height: 100%;
}

.DividerNotes div {
  border-left: 2px solid transparent;
}

.RestDay {
  text-align: center;
}

.RestDayHeading {
  font-weight: 500;
  font-size: 1.375rem;
  text-align: center;
  color: var(--color-text-primary, #181818);
  margin: 0;
  margin-bottom: 0.5rem;
}

.DayFinished {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.6rem;
  gap: 0.5rem;
  background-color: var(--color-success-light);
  border-radius: var(--border-radius-base);
}

.DayCompleteText {
  color: var(--color-text-primary, #181818);
  text-align: center;
  font-family: var(--font-family-demi-bold, 'PlusJakartaSans-DemiBold', sans-serif);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  text-transform: capitalize;
}

.FinishedDayButton {
  width: 85%;
  height: 40px;
  display: flex;
  color: #707070;
  font-size: 12px;
  cursor: pointer;
  margin: 2rem 0 10px 0;
  border-radius: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;
  border: 2px solid #707070;
  background-color: transparent;
}

.FinishedDayButton:hover {
  background-color: rgba(102, 101, 101, 0.746);
  transition: all 0.1s linear;
  color: white;
}

.DayButton,
.CloseButton {
  width: 85%;
  height: 40px;
  display: flex;
  color: #707070;
  font-size: 12px;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 8px;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;
  border: 2px solid #707070;
  background-color: transparent;
}

.CloseButton {
  width: 50%;
  margin: 0;
}

.DayButton:hover,
.CloseButton:hover {
  background-color: rgba(102, 101, 101, 0.746);
  transition: all 0.1s linear;
  color: white;
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.EditIcon {
  height: 18px;
  width: 18px;
  margin-right: 7px;
}

.DayButton:hover .EditIcon {
  filter: grayscale(1) brightness(2);
}

.DropDownContainer {
  position: absolute;
  left: 12.15rem;
  top: 1rem;
  font-size: 12px;
  z-index: 2;
  margin: 0;
}

.DropDownContainerQuickEdit {
  position: absolute;
  left: 20.05rem;
  top: 2rem;
  font-size: 12px;
  z-index: 2;
  z-index: 11002;
  margin: 0;
}

.DropDownBox {
  background-color: #3c4148;
  border-radius: 7px;
  height: 55px;
  width: 250px;
  position: absolute;
  top: -55px;
  left: -54px;
  color: #c0c0c0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.DropDownButtons {
  margin: auto;
  transition: color 0.1s ease;
}

.DropDownButtons:hover {
  cursor: pointer;
  color: white;
}

.ArrowDown {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #3c4148;
}

.AddButtonWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.AddButtonWrapper:last-child {
  margin-top: 0.5rem;
}

.AddText {
  margin-left: 0.75rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  text-transform: uppercase;
  color: var(--color-text-primary, #181818);
}

.EditButtonsContainer {
  margin-top: 1rem;
  margin-right: 0.5rem;
}

.AthleteFeedbackWrapper p,
.WorkoutNotesFeedback {
  font-size: 13px;
  font-weight: 400;
  color: rgba(219, 219, 219, 0.938);
  margin-top: 5px;
  margin-bottom: 5px;
}

.WorkoutNotesFeedback {
  margin-bottom: 40px;
}

.ExerciseFeedbackText {
  font-size: 13px;
}

.RowInputs {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0.41rem;
}

.NutrientHeading {
  max-width: 4.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.0175rem;
  font-family: var(--font-family-demi-bold, 'PlusJakartaSans-DemiBold', sans-serif);
}

.InputWrapper {
  width: 5.1rem;
}

.ColumnHeading {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  width: 5.1rem;
  flex-grow: 1;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  font-family: var(--font-family-demi-bold, 'PlusJakartaSans-DemiBold', sans-serif);
  margin-bottom: 0.5rem;
}

.AchievedValue {
  display: flex;
  justify-content: center;
  width: 5.1rem;
  flex-grow: 1;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-primary, #181818);
}

.Notes {
  margin-top: 1.09rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-right: 0.5rem;
}

.MicroGoalInput {
  max-width: 10.7rem;
}

.MicroGoalInputWhenAchieved {
  width: 5.1rem;
}

.Divider {
  display: flex;
  height: 0.0625rem;
  align-self: stretch;
  margin: 0.75rem 0;
  background-color: var(--color-divider, #dbdbdb);
  width: 100%;
}

.TextCentered {
  text-align: center;
}

.PercentageTotalError {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  color: var(--color-danger, #ff5050);
  display: flex;
  justify-content: flex-end;
}

.DayTitleInput,
.DayTitle {
  border: 0;
  outline: none;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-primary, #181818);
  padding: 0;
  margin: 0;
  max-width: 184px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TitleActionButtons {
  display: flex;
  gap: 0.25rem;
}

.DayTitleWrapper {
  display: flex;
  align-items: center;
}

.UneditableTitleWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.CursorPointer {
  cursor: pointer;
}

.UneditableTitleWrapper path {
  fill: var(--color-icon-stroke, #404040);
  stroke: var(--color-icon-stroke, #404040);
}

.Content::-webkit-scrollbar {
  width: 0.25rem;
}

.Content::-webkit-scrollbar-track {
  background: var(--color-menu-background, #edeff1);
  margin-top: 0.5rem;
  margin-bottom: 25rem;
  border-radius: 50px;
  border: 0;
}

.Content::-webkit-scrollbar-thumb {
  background-color: var(--color-accent-primary, #7782f8);
  border-radius: 50px;
  border: 0;
}

.MarginRight {
  margin-right: 0.5rem;
}

.MinHeight {
  height: 1rem;
}
