.Container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: 4.5rem;
  padding: 1rem 1.25rem;
  background: inherit;
  border-bottom: 1px solid var(--color-divider, #dbdbdb);
}

.ContentArea {
  display: flex;
  flex-direction: row;
  padding-top: 4.5rem;
}

.Sidebar {
  width: 13.8125rem;
}

.MainContent {
  overflow-y: auto;
  height: calc(100vh - 4.5rem);
  padding: 1rem 1.25rem;
  width: calc(100% - 13.8125rem);
  background: var(--color-background, #f3f4f5);
}
