.form {
  display: flex;
}

.Title {
  border-bottom: 1px solid var(--color-divider, #dbdbdb);
  margin-bottom: 20px;
}

.Title h4 {
  margin-bottom: 8px;
  color: var(--color-text-primary, #181818);
}

/* .topInformation {
  border-bottom: 1px solid #d60509;
} */

.row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.inputBlock,
.inputBlockPlan {
  width: 100%;
  margin-bottom: 1.5rem;
}

.inputBlockPlan {
  width: 48%;
}

.inputBlock input,
.inputBlockPlan select,
.inputBlockPlan input {
  font-size: 16px;
}

.inputBlockPlan {
  color: var(--color-text-primary, #181818);
}

.inputBlockPlan select {
  border: 0;
  height: 3rem;
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  border-radius: 0.4375rem;
  background-color: #3c4148;
  border: 0.125rem solid transparent;
  transition: border-color 0.1s ease;
  color: #cacbd5;
  outline: none;
  cursor: pointer;
}

.inputBlock textarea {
  overflow: hidden;
  width: 100%;
  outline: none;
  resize: none;
  box-sizing: border-box;
  border: 0;
  height: 3rem;
  min-height: 62px;
  display: flex;
  font-size: 16px;
  padding: 0.7rem 0.5rem;
  align-items: center;
  border-radius: 0.4375rem;
  background-color: #3c4148;
  border: 0.125rem solid transparent;
  transition: border-color 0.1s ease;
  color: #cacbd5;
}

.label {
  color: var(--color-text-primary, #181818);
  display: block;
  cursor: pointer;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.125rem;
}

.input {
  border: 0;
  width: 100%;
  outline: none;
  color: white;
  font-size: 1rem;
  padding: 0.5rem 0;
  height: 52px;
  background-color: #333333;
  transition: all 0.1s ease-in-out;
  border-bottom: 1px solid #d0d9e1;
  -ms-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}

.input:disabled {
  color: #909090;
}

.input:focus {
  border-bottom: 1px solid #d60509;
}

.error {
  opacity: 0.9;
  font-weight: 500;
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 0.75rem;
  color: rgb(209, 47, 47);
}

.coachProfileButtons {
  display: flex;
  flex-direction: row;
  margin-top: 1.25rem;
  width: 200px;
}

.saveCoachButton {
  border: 0;
  outline: none;
  cursor: pointer;
  width: fit-content;
}

.InputLabels,
.FileInputLabels {
  color: var(--color-text-primary, #181818);
  font-size: 14px;
}

.FileInputLabels {
  color: #333333 !important;
}

.OtherImage {
  max-width: 200px;
  max-height: 150px;
}

/* file inputs */
/* .file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
} */

.fileInput {
  width: fit-content;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  cursor: pointer;
  transition: all 0.1s linear;
  text-transform: uppercase;
  border: none;
}

.fileInput input[type='file'] {
  display: none;
}

.fileInput button,
.lightUploadButton {
  width: fit-content;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-text-primary, #181818);
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  transition: all 0.1s linear;
}

.fileInput button:hover {
  color: var(--color-text-primary, #181818);
  transition: all 0.1s linear;
}

.lightUploadButton {
  color: var(--color-text-primary, #181818);
}

.lightUploadButton:hover {
  color: var(--color-text-primary, #181818);
  transition: all 0.1s linear;
}

/* input:hover + label,
input:focus + label {
  transform: scale(1.02);
}

input:focus + label {
  outline: 1px solid #000;
  outline: -webkit-focus-ring-color auto 2px;
} */

.ImageContainer {
  display: inline-block;
  font-size: 0px;
  margin-top: 3px;
  overflow: clip;
  box-shadow: 0 3px 7px rgb(0 0 0 / 0.3);
  border-radius: 4px;
}

.ImageContainer img {
  width: 75px;
  height: 55px;
  object-fit: cover;
  object-position: center center;
}

.ListOfItems {
  padding: 0;
  width: 100%;
}
