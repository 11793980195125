.cardContainer {
  background-color: #25292c;
  color: white;
  border-radius: 16px;
  max-width: 255px;
  box-shadow: 0.5px 0.5px 16px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  height: 100%;
}

.cardContent {
  padding: 1em;
  position: relative;
  padding-bottom: 0;
}

.cardContent p {
  font-family: 'Industry-Medium';
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 10px;
}

.clientName {
  font-family: 'Industry-Bold';
  text-transform: uppercase;
  font-size: 16px;
  color: #e94811;
  margin: 0;
  margin-left: 5px;
}

.pics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0.5em;
}

.pic {
  display: grid;
  grid-template-columns: 1fr;
}

.testimonialPic img {
  max-width: 100%;
  border-radius: 10px;
  object-fit: contain;
  margin: 5px;
}

.testimonialPic p {
  text-align: center;
}

.testimonialPic {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.testimonialCopy {
  margin-bottom: 5px;
}

@media (min-width: 768px) {
  .testimonialPic img {
    max-width: 113px;
  }

  .gridContent {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 20px;
    padding-bottom: 0;
    background-color: #25292c;
    color: white;
    font-size: 12px;
    font-family: 'Industry-Medium';
    text-transform: uppercase;
    border-radius: 16px;
    width: 286px;
    height: 100%;
    box-shadow: 0.5px 0.5px 16px rgba(0, 0, 0, 0.5);
  }

  .cardContainer {
    font-size: 16px;
    min-width: 380px;
  }

  .left {
    display: flex;
    flex-direction: row;
    text-align: center;
    min-height: 190px;
  }

  .leftSingle .testimonialPic {
    height: 100%;
    max-width: 100%;
  }

  .testimonialPic {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .cardContainer {
    height: auto;
  }
}
