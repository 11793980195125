.ProgrammingSettings {
  border-bottom: 1px solid var(--color-divider, #dbdbdb);
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ParametersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ParametersHeading {
  margin: 0;
  padding: 0;
  color: var(--color-text-primary, #181818);
  margin-right: 0.875rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.SetParams {
  position: relative;
  display: flex;
  align-items: center;
}

.SetParam {
  width: 8.5rem;
  margin-right: 1rem;
  z-index: 20;
  position: relative;
}

.AddSetIcon {
  width: 1rem;
  height: 1.25rem;
  color: #3c4148;
  fill: currentColor;
  transition: all 0.1s ease;
}

.AddSetBtn:hover .AddSetIcon {
  color: silver;
}

.AddSetBtn {
  border: none;
  cursor: pointer;
  background: none;
}

.AddSetBtn:focus {
  outline: none;
}

.Buttons {
  display: flex;
  gap: 1rem;
}
