.Column {
  display: flex;
  flex-direction: column;
  background-color: var(--table-background, #ffffff);
  padding: 1rem 1rem 0 1rem;
  border-radius: var(--border-radius-base, 0.5rem);
  height: 100%;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.Table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: var(--table-background, #ffffff);
  border-radius: var(--border-radius-base, 0.5rem);
}

/* <thead> */
.TableHeader {
  border-radius: var(--border-radius-base, 0.5rem);
  height: 3.5rem;
  z-index: 2;
  position: relative;
  position: sticky;
}

/* <th> */
.TableHeading {
  text-align: left;
  background: var(--table-header-background, #edeff1);
  position: sticky;
  top: 0;
  color: var(--color-text-secondary, #959595);
  padding: 0.75rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
}

.SmallerPadding {
  padding: 0.25rem 0.75rem;
}

.TableHeading:first-child {
  border-top-left-radius: var(--border-radius-large, 1rem);
  border-bottom-left-radius: var(--border-radius-large, 1rem);
  padding-left: 1rem;
}

.TableHeading:last-child {
  border-top-right-radius: var(--border-radius-large, 1rem);
  border-bottom-right-radius: var(--border-radius-large, 1rem);
  padding-right: 1rem;
}

.TableBody {
  z-index: 1;
  position: relative;
}

.TableBodyRow {
  cursor: pointer;
  position: relative;
}

.TableBodyRow td {
  padding: 0 0.75rem;
  transition: background-color 0.3s, border-color 0.3s;
}

.TableBodyRow:hover td {
  background-color: var(--table-row-hover-background, #fafafa);
}

.TableBodyRow:hover td:first-child {
  border-top-left-radius: var(--border-radius-large, 1rem);
  border-bottom-left-radius: var(--border-radius-large, 1rem);
}

.TableBodyRow:hover td:last-child {
  border-top-right-radius: var(--border-radius-large, 1rem);
  border-bottom-right-radius: var(--border-radius-large, 1rem);
}

/* <td> */
.TableData {
  width: 280px;
  max-height: 60px;
  min-height: 60px;
  height: 60px;
  padding: 1rem 0.75rem;
  color: var(--color-text-primary, #181818);
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  position: relative;
}

.TableData:first-child {
  width: 1%;
  padding-left: 1rem;
}

.TableData:last-child {
  width: 5%;
  padding-right: 1rem;
}

.Loading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.NoData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: var(--color-text-primary, #181818);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.TableButton {
  background-image: linear-gradient(#dd1a18, #f4730c);
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}