.Container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.TopSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

.FilesWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.AddProgramButtonWrapper {
  border: 0;
  right: 3rem;
  bottom: 5rem;
  position: fixed;
  background: none;
  outline: none;
}

.AddProgramButtonWrapper:hover .AddProgramButton {
  background-image: linear-gradient(#e04c49, #e9a065);
  cursor: pointer;
}

.AddProgramButtonWrapper:hover .AddProgramText {
  color: #cacbd5;
  cursor: pointer;
}

.AddProgramText {
  transform: rotate(90deg);
  font-size: 16px;
  color: #707070;
  position: absolute;
  right: 10%;
  top: -220%;
  width: 140px;
  height: 100px;
  font-family: 'Industry-Bold', sans-serif;
}

.AddProgramButton {
  border-radius: 8px;
  font-size: 50px;
  height: 58px;
  width: 58px;
  outline: none;
  background-image: linear-gradient(#dd1a18, #f4730c);
  border-width: 0;
}

.NoDocuments {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10rem;
}

.BulkActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
