.ClientWrapper {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.ClientWrapper a {
  display: flex;
  width: fit-content;
  height: fit-content;
}

.ContentWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 1rem;
  border-radius: var(--border-radius-large, 1rem);
  background-color: var(--color-card-background, #ffffff);
  min-height: 7rem;
  max-width: 18rem;
  transition: all 0.1s ease-in-out;
}

.ContentWrapper:hover {
  box-shadow: var(--color-box-shadow, 0px 4px 20px 0px rgba(0, 0, 0, 0.1));
}

.RightContainer {
  height: 100%;
  margin-left: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Name {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  color: var(--color-text-primary, #181818);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  margin: 0;
}

.Description {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.5rem */
  text-transform: uppercase;
  margin: 0;
}

@media (min-width: 730px) {
  .ClientsWrapper {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .ClientsWrapper {
    width: 33.33%;
  }
}

@media (min-width: 1500px) {
  .ClientsWrapper {
    width: 25%;
  }
}
