.ProgramsContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.ToolBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SearchPrograms {
  width: 14.8125rem;
}

.SearchPrograms form {
  width: fit-content;
  height: 100%;
}

.NoPrograms {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.BulkActions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media (min-width: 730px) {
  .CardWrapper {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .CardWrapper {
    width: 33.33%;
  }
}

@media (min-width: 1500px) {
  .CardWrapper {
    width: 25%;
  }
}
