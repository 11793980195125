.Container {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 23.3125rem;
  padding: 1.5rem;
  padding-top: 0;
  background-color: var(--color-card-background, #ffffff);
  transition: all 0.2s linear;
  overflow-x: hidden;
  z-index: 1000;
  border-top-left-radius: var(--border-radius-large, 1rem);
  height: calc(100% - 5.31rem);
  transform: translateX(100%);
}

.Container.left {
  top: 5.31rem;
  left: 0;
}

.Container.right {
  top: 5.31rem;
  right: 0;
}

.Container.right.active {
  transform: translateX(0%);
}

.Container.top {
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
}

.Container.bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;
}

.SideDrawerInner {
  width: auto;
  position: relative;
}
