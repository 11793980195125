.AccordionRoot {
  width: 100%;
}

.AccordionItem {
  outline: none;
  border: 0;
  background-color: white;
  overflow: hidden;
  margin-top: 1px;
  border-top: 1px solid var(--color-divider, #dbdbdb);
  padding: 1.5rem 0;
}

.AccordionItem:first-child {
  margin-top: 0;
}

.AccordionItem:focus-within {
  position: relative;
  z-index: 1;
}

.AccordionHeader {
  display: flex;
  margin: 0;
}

.AccordionTrigger {
  outline: none;
  border: 0;
  padding: 0;
  height: 1.4375rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: white;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.44375rem */
  letter-spacing: -0.0275rem;
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  color: var(--color-text-primary, #181818);
}

.AccordionTrigger h3 {
  margin: 0;
}

.AccordionContent {
  overflow: hidden;
  padding-top: 1.5rem;
}
.AccordionContent[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionChevron {
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  margin-right: 1rem;
}
.AccordionTrigger[data-state='open'] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
    padding-top: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
    padding-top: 2.25rem;
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
    padding-top: 2.25rem;
  }
  to {
    height: 0;
    padding-top: 0;
  }
}
