.Column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48%;
  margin-bottom: 0.275rem;
  position: relative;
}

.Column label {
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  text-align: left;
  margin-bottom: 4px;
}

.ColorPickerContainer {
  margin-top: 0.875rem;
}

.Row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.Error {
  font-size: 0.8rem;
  color: var(--color-danger, #ff5050) !important;
  display: block;
  position: absolute;
  bottom: -3.125rem;
  left: 0;
  min-height: 36px;
}
