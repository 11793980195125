.section {
  padding-bottom: 7.5rem;
}

@media (min-width: 768px) {
  .cardsWrapper {
    margin-top: 1.5rem;
    grid-gap: 1rem;
    display: flex;
    overflow: auto;
    padding: 8px 10px 35px 10px;
  }

  .cardsWrapper::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 50px;
  }

  .cardsWrapper::-webkit-scrollbar-thumb {
    background: #e94811;
  }

  .section {
    padding-bottom: 6.54rem;
  }
}
