.ClientProfile {
  height: 100%;
  margin: auto;
  padding: 2.5rem;
  width: 25rem;
  background-color: var(--color-card-background, #ffffff);
  border-radius: var(--border-radius-base, 0.5rem);
}

.ClientName {
  color: var(--color-text-primary, #181818);
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 1.2375rem */
  letter-spacing: -0.03375rem;
  margin: 0;
}

.TopInformation {
  margin-bottom: 10px;
  margin-top: 10px;
  margin: auto;
}

.InputBlock {
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ImageDiv {
  background-size: contain;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;
  z-index: 1;
}
