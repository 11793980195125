.InputContainer {
  all: unset;
  width: 100%;
  position: relative;
}

.Input {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: var(--border-radius-base, 0.5rem);
  min-width: 100%;
  width: 100%;
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  border: 1px solid transparent;
  outline: none;
  background: var(--color-input-background, #dbdbdb);
  color: black;
  text-align: left;
  height: 2.625rem;
  transition: border-color 0.15s ease-in-out;
}

/* Used to ensure the icons are placed inside the input */
.RelativeWrapper {
  display: flex;
  position: relative;
}

.ErrorInput {
  border: 1px solid var(--color-input-border-error, #ff5050);
}

.Error {
  font-size: 0.8rem;
  color: var(--color-text-input-error, #ff5050);
  display: block;
  position: absolute;
  bottom: -16px;
  left: 0;
}

.LeftIcon svg,
.RightIcon svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1.125rem;
  height: 1.125rem;
  z-index: 1;
}

.LeftIcon svg {
  left: 1rem;
}

.RightIcon svg {
  right: 1rem;
}

.LeftIcon svg path,
.RightIcon svg path {
  stroke: var(--color-icon-stroke, #404040);
}

.WithIconLeft {
  padding-left: 2.7rem !important;
}

.WithIconRight {
  padding-right: 2.7rem !important;
}

.Input::placeholder {
  color: var(--color-text-input-inactive, #959595);
}

.Input:focus {
  outline: none;
  border: 1px solid var(--color-input-border-active, #959595);
}

.Input:read-only {
  cursor: not-allowed;
  opacity: 0.5;
}

.Label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
