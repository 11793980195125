@font-face {
  font-family: 'Industry-Black';
  src: url(./fonts/Industry-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Industry-Bold';
  src: url(./fonts/Industry-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Industry-Medium';
  src: url(./fonts/Industry-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Industry-Book';
  src: url(./fonts/Industry-Book.ttf) format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-Light';
  src: url(./fonts/PlusJakartaSans-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-Regular';
  src: url(./fonts/PlusJakartaSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-Medium';
  src: url(./fonts/PlusJakartaSans-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-Bold';
  src: url(./fonts/PlusJakartaSans-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'PlusJakartaSans-DemiBold';
  src: url(./fonts/PlusJakartaSans-SemiBold.ttf) format('truetype');
}

html,
#root {
  height: 100%;
  font-size: var(--font-size-base, 16px);
}

body {
  margin: 0;
  height: 100%;
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--color-background, #f3f4f5);
}

span,
label,
p,
h5,
textarea,
input,
th {
  font-family: var(--font-family-regular, 'PlusJakartaSans-Regular', sans-serif);
}

label {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-input-label, #181818);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
}

h1 {
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 2.85rem */
  letter-spacing: -0.03rem;
  color: var(--color-text-primary, #181818);
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--color-input-background, #dbdbdb) inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--color-input-background, #dbdbdb) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-text-primary, #181818) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-accent-primary, #7782f8);
  -webkit-border-radius: var(--border-radius-medium, 0.75rem);
  border-radius: var(--border-radius-medium, 0.75rem);
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar:horizontal {
  background-color: var(--color-divider, #dbdbdb);
  width: 1px;
  height: 0.25rem;
  border-radius: var(--border-radius-medium, 0.75rem);
}
