.Container {
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-base, 0.5rem);
  width: 100%;
  position: relative;
  gap: 1rem;
  transition: all 0.1s ease-in-out;
  padding: 0.5rem;
  cursor: pointer;
}

.Container:hover {
  background-color: var(--color-accent-primary-light, #c1c5f4);
}

.NotificationDetails {
  padding: 0;
  margin: 0;
  flex-grow: 1;
  align-items: center;
  color: var(--color-text-primary, #181818);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  position: relative;
  margin-right: 10px;
}

.LargeCircle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #f5f5f5;
  position: absolute;
}

.CirclesWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  min-width: 2.5rem;
  height: 2.5rem;
}

.SmallSquare {
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.SmallSquare:first-child {
  right: 0;
  bottom: -3%;
  z-index: 2;
}

.SmallSquare:last-child {
  left: 0;
  top: 0;
  z-index: 1;
}

.UnreadDot {
  align-self: center;
  width: 0.625rem;
  height: 0.625rem;
  background-color: var(--color-accent-primary, #7782f8);
  border-radius: 50%;
  margin-left: 10px;
  flex-shrink: 0;
}

.DetailsAndDotWrapper {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
}
