.Week {
  display: flex;
  margin-bottom: 2rem;
}

.WeekHeadingDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.WeekHeadingStyle {
  font-family: var(--font-family-medium, 'PlusJakartaSans-Medium', sans-serif);
  color: var(--color-text-primary, #181818);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 500;
  line-height: 105%; /* 1.44375rem */
  letter-spacing: -0.0275rem;
  padding: 0;
  margin: 0;
}

.WeekActionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  z-index: 2;
}

.DuplicateWeek {
  margin-left: 10px;
  cursor: pointer;
  background-color: transparent;
  letter-spacing: 1px;
  font-size: 14px;
  transition: 0.1s ease-in-out;
  position: relative;
  left: 65.5vw;
  border: none;
}

.DuplicateWeek:hover {
  font-weight: 400;
  transition: 0.1s ease-in-out;
}

.DuplicateDiv {
  display: flex;
  flex-direction: row;
  margin-right: 0.5rem;
}

.DeleteDiv {
  display: flex;
  flex-direction: row;
}

.Week {
  overflow-x: auto;
}

@media (min-width: 1000px) {
  .Week {
    padding-right: 40px;
  }
}

@media (min-width: 1050px) {
  .Week {
    padding-right: 65px;
  }
}

@media (max-width: 1366px) {
  .Week {
    padding-right: 80px;
  }
}
