.Footer {
  font-size: 14px;
  padding: 2em 2em 0 4.33vw;
  margin: 0;
  background-color: var(--color-menu-background, #edeff1);
}

.Footer a:hover {
  color: var(--color-accent-primary, #7782f8);
}

.FooterContainer {
  display: grid;
  grid-template-rows: 1fr 0.8fr 1fr;
  gap: 25px;
}

.Paragraph {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  text-transform: uppercase;
  color: var(--color-text-primary, #181818);
  margin: 0;
  margin-bottom: 1.5rem;
}

.FooterContainer ul {
  font-style: normal;
  font-weight: normal;
  font-size: 11.67px;
  line-height: 15px;
}

.Email {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 1.9rem */
  letter-spacing: -0.02rem;
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  color: var(--color-text-primary, #181818);
}

.AtInEmail {
  font-family: var(--font-family-bold, 'PlusJakartaSans-Bold', sans-serif);
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 95%;
  letter-spacing: -0.02rem;
  color: var(--color-accent-primary, #7782f8);
}

.Logo {
  margin-bottom: 2.5rem;
}

.PlayStoreBadges {
  display: flex;
  gap: 10px;
}

.PlayStoreBadges img {
  width: 100px;
  max-height: 30px;
}

.FooterMiddle {
  grid-row-start: 2;
  margin-top: 2.33em;
  margin-right: 0vw;
  margin-bottom: 1.02em;
}

.FooterRHS {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-row-start: 3;
}

.FooterRHS ul {
  padding: 0;
  list-style: none;
}

.FooterRHS ul li {
  line-height: 2;
  display: flex;
  align-items: center;
}

.FooterRHS a {
  display: flex;
}

.FooterRHS li img {
  width: 20px;
  margin-right: 8px;
}

.FooterBottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 0.77rem;
  border-top: 1px solid var(--color-divider, #dbdbdb);
  padding: 3.31rem 0 2.25rem;
  align-items: center;
}

.FooterBottom div {
  display: flex;
  flex-direction: column;
}

.FooterBottom p {
  margin: 0;
  align-self: flex-end;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  color: var(--color-text-primary, #181818);
}

.FooterBottom a {
  cursor: pointer;
  color: var(--color-text-primary, #181818);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
}

.FooterBottom a:nth-child(1) {
  align-self: flex-end;
}

.SocialLink span {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  color: var(--color-text-primary, #181818);
  margin-left: 0.5rem;
}

@media (min-width: 768px) {
  .Footer {
    padding: 5.21em 8.33em 0 8.33vw;
  }
  .FooterContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 2.88rem;
  }

  .FooterMiddle {
    justify-self: end;
    grid-row-start: span 1;
    grid-column-start: 4;
    margin: 0;
  }

  .FooterRHS {
    display: flex;
    grid-row-start: span 1;
    grid-row-end: span 1;
    grid-column-start: 5;
    justify-self: flex-end;
  }

  .FooterRHS h3 {
    margin-top: 0;
  }

  .FooterBottom div {
    flex-direction: row;
    gap: 15px;
  }
}
