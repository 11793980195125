.Wrapper {
  width: 100%;
}

.Title {
  color: var(--color-text-primary, #181818);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 1.25rem;
}

.EmptyList {
  color: #707070;
}

.ListWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.69rem;
  margin-left: -1rem;
}

.Divider {
  border-radius: var(--border-radius-base, 0.5rem);
  border: 0.0625rem solid var(--color-divider, #dbdbdb);
  height: 0.0625rem;
}

.NoClientsNeedTraining {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
