.TableRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  list-style-type: none;
}

.TableSetNumber,
.TableSetNumberFinished {
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  color: var(--color-text-primary, #181818);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 0.9625rem */
  letter-spacing: -0.0175rem;
  text-align: left;
  margin-right: 1rem;
  width: 1.875rem;
  height: 100%;
}

.InputContainer {
  padding: 0;
  border: 0;
  width: 8.5rem;
  outline: none;
  background-color: transparent;
  text-align: center;
  margin: 0;
  position: relative;
}

.InputContainer:nth-child(3) {
  margin: 0 1rem;
}

.TableInput {
  border: 0;
  width: 99%;
  height: 2.625rem;
  padding: 0.75rem 1rem;
  outline: none;
  background: var(--color-input-background, #dbdbdb);
  color: var(--color-text-primary, #181818);
  border-radius: var(--border-radius-base, 0.5rem);
  text-align: center;
  margin: 0;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
  transition: border-color 0.15s ease-in-out;
}

.TableInput:focus {
  outline: none;
  border: 1px solid var(--color-input-border-active, #959595);
}

.TableInputOriginal {
  box-sizing: border-box;
  border-radius: 0;
  border-top-left-radius: var(--border-radius-base, 0.5rem);
  border-bottom-left-radius: var(--border-radius-base, 0.5rem);
}

.TableInputAchieved {
  box-sizing: border-box;
  padding: 0;
  border: 0;
  height: 2.625rem;
  width: 99%;
  padding: 0.75rem 1rem;
  outline: none;
  background: var(--color-input-background, #dbdbdb);
  color: var(--color-text-primary, #181818);
  border-top-right-radius: var(--border-radius-base, 0.5rem);
  border-bottom-right-radius: var(--border-radius-base, 0.5rem);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 1.1rem */
  letter-spacing: -0.03rem;
}

.TableInputEmpty {
  background-color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.FlexContainer {
  display: flex;
}

.AutofillButton,
.AutofillButtonHidden {
  position: absolute;
  top: -31px;
  right: 50%;
  transform: translateX(50%);
  cursor: pointer;
  background: var(--color-accent-primary-light, #c1c5f4);
  padding: 0.5rem;
  border-radius: var(--border-radius-xsmall, 0.25rem);
  border: none;
  outline: none;
  text-align: center;
  animation-fill-mode: forwards;
  font-weight: 500;
  color: var(--color-text-primary, #181818);
  transition: all 0.1s linear;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 1.3125rem */
  text-transform: uppercase;
  box-shadow: var(--color-box-shadow, 0px 4px 20px 0px rgba(0, 0, 0, 0.1));
}

.AutofillButton:hover,
.AutofillButtonHidden:hover {
  background: var(--color-accent-primary, #7782f8);
  color: var(--color-text-on-accent, #ffffff);
  outline: none !important;
  cursor: pointer;
  transition: all 0.1s linear;
}

.AutofillButton {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.AutofillButtonHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.FinishedSetIcons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--border-radius-xsmall, 0.25rem);
  margin-left: 1rem;
}

.Tick {
  background-color: var(--color-success, #40c463);
}

.Cross {
  background-color: var(--color-danger, #ff5050);
}

.Tick svg {
  fill: var(--button-text-color-success, #ffffff);
}

.Cross svg path {
  stroke: var(--button-text-color-danger, #ffffff);
}
